import React, { useState, useEffect } from 'react';
import api from '../api';
import { format } from 'date-fns'; // para manipular fechas
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faFileInvoiceDollar,
  faExclamationTriangle, faPiggyBank, faHandHoldingUsd,
  faCoins
} from '@fortawesome/free-solid-svg-icons';

const Dashboard = () => {
  const [clientes, setClientes] = useState([]);
  const [facturas, setFacturas] = useState([]);
  const [facturasPagadasMes, setFacturasPagadasMes] = useState(0);
  const [facturasPendientes, setFacturasPendientes] = useState(0);
  const [facturasTotales, setFacturasTotales] = useState(0);

  const [facturasResumen, setFacturasResumen] = useState({
    facturasPagadasMes: 0,
    facturasPendientes: 0,
    facturasTotales: 0,
    totalFacturado: 0,
    totalAbonos: 0,
    totalAdeudado: 0
  });

  const [honorariosResumen, setHonorariosResumen] = useState({
    honorariosPagadasMes: 0,
    honorariosPendientes: 0,
    honorariosTotales: 0,
    totalHonorados: 0,
    totalAbonosHonorarios: 0,
    totalAdeudadoHonorarios: 0
  });

  const [totalFacturado, setTotalFacturado] = useState(0);
  const [totalAbonos, setTotalAbonos] = useState(0);
  const [totalAdeudado, setTotalAdeudado] = useState(0);

  // Nuevos estados para Honorarios
  const [honorarios, setHonorarios] = useState([]);
  const [honorariosPagadasMes, setHonorariosPagadasMes] = useState(0);
  const [honorariosPendientes, setHonorariosPendientes] = useState(0);
  const [honorariosTotales, setHonorariosTotales] = useState(0);

  const [totalHonorados, setTotalHonorados] = useState(0);
  const [totalAbonosHonorarios, setTotalAbonosHonorarios] = useState(0);
  const [totalAdeudadoHonorarios, setTotalAdeudadoHonorarios] = useState(0);


  // Función para obtener el token del almacenamiento local
  const getToken = () => {
    return localStorage.getItem('token'); // o el nombre que estés usando para almacenar el token
  };

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const res = await api.get('/clientes', {
          headers: {
            Authorization: `Bearer ${getToken()}` // Agregar el token al encabezado
          }
        });
        setClientes(res.data);
      } catch (err) {
        console.error("Error fetching clientes:", err);
      }
    };

    const fetchFacturas = async () => {
      try {
        const res = await api.get('/facturas', {
          headers: {
            Authorization: `Bearer ${getToken()}` // Agregar el token al encabezado
          }
        });
        const facturasData = Array.isArray(res.data) ? res.data : [];

        setFacturas(facturasData);
        setFacturasTotales(facturasData.length);

        // Calcular las facturas pagadas en el mes actual
        const facturasPagadas = facturasData.filter(
          (factura) => factura.estado === 'pagada' &&
            format(new Date(factura.fechaPago), 'MM/yyyy') === format(new Date(), 'MM/yyyy')
        );
        setFacturasPagadasMes(facturasPagadas.length);

        // Calcular las facturas pendientes
        const facturasPendientes = facturasData.filter((factura) => factura.estado === 'pendiente' || factura.estado === "abonada");
        setFacturasPendientes(facturasPendientes.length);

        // Calcular total facturado (suma del monto de todas las facturas)
        const totalFacturado = facturasData.reduce((acc, factura) => acc + factura.monto, 0);
        setTotalFacturado(totalFacturado);

        // Calcular total abonos (suma de todos los abonos realizados)
        const totalAbonos = facturasData.reduce((acc, factura) => acc + factura.total_abonado, 0);
        setTotalAbonos(totalAbonos);

        // Calcular total adeudado (total facturado - total abonos)
        const totalAdeudado = totalFacturado - totalAbonos;
        setTotalAdeudado(totalAdeudado);
      } catch (err) {
        console.error("Error fetching facturas:", err);
      }
    };


    const fetchHonorarios = async () => {
      try {
        const res = await api.get('/honorarios', {
          headers: {
            Authorization: `Bearer ${getToken()}` // Agregar el token al encabezado
          }
        });
        const honorariosData = res.data;

        setHonorarios(honorariosData);
        setHonorariosTotales(honorariosData.length);

        // Calcular los honorarios pagados en el mes actual
        const honorariosPagadas = honorariosData.filter(
          (honorario) => honorario.estado === 'pagada' &&
            format(new Date(honorario.fechaPago), 'MM/yyyy') === format(new Date(), 'MM/yyyy')
        );
        setHonorariosPagadasMes(honorariosPagadas.length);

        // Calcular los honorarios pendientes
        const honorariosPendientes = honorariosData.filter((honorario) => honorario.estado === 'pendiente' || honorario.estado === "abonada");
        setHonorariosPendientes(honorariosPendientes.length);

        // Calcular total honorado (suma del monto de todos los honorarios)
        const totalHonorados = honorariosData.reduce((acc, honorario) => acc + honorario.monto, 0);
        setTotalHonorados(totalHonorados);

        // Calcular total abonos (suma de todos los abonos realizados)
        const totalAbonosHonorarios = honorariosData.reduce((acc, honorario) => acc + honorario.total_abonado, 0);
        setTotalAbonosHonorarios(totalAbonosHonorarios);

        // Calcular total adeudado (total honorado - total abonos)
        const totalAdeudadoHonorarios = totalHonorados - totalAbonosHonorarios;
        setTotalAdeudadoHonorarios(totalAdeudadoHonorarios);
      } catch (err) {
        console.error("Error fetching honorarios:", err);
      }
    }


    fetchClientes();
    fetchFacturas();
    fetchHonorarios();
  }, []);


  useEffect(() => {
    const fetchResumenFacturas = async () => {
      try {
        const response = await api.get('/facturas/resumen', {
          headers: { Authorization: `Bearer ${getToken()}` }
        });
        //console.log('Resumen Facturas:', response.data);
        setFacturasResumen(response.data);
      } catch (error) {
        console.error('Error al obtener resumen de facturas:', error);
      }
    };

    const fetchResumenHonorarios = async () => {
      try {
        const response = await api.get('/honorarios/resumen-honorarios', {
          headers: { Authorization: `Bearer ${getToken()}` }
        });
        setHonorariosResumen(response.data);
      } catch (error) {
        console.error('Error al obtener resumen de honorarios:', error);
      }
    };

    fetchResumenFacturas();
    fetchResumenHonorarios();
  }, []);



  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-semibold mb-6 text-center">Panel de Administración</h1>

       {/* Resumen de Facturas */}
      <section className="mb-12">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Resumen de Facturas</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Facturas Pagadas este mes */}
          <div className="bg-white shadow-xl rounded-lg p-3 flex items-center">
            <div className="p-3 bg-green-100 text-green-400 rounded-full mr-4">
              <FontAwesomeIcon icon={faCheckCircle} className="text-2xl" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Pagadas este mes</h3>
              <p className="text-lg text-gray-600">{facturasResumen.facturasPagadasMes}</p>
            </div>
          </div>

          {/* Facturas Pendientes */}
          <div className="bg-white shadow-xl rounded-lg p-3 flex items-center">
            <div className="p-3 bg-red-100 text-red-400 rounded-full mr-4">
              <FontAwesomeIcon icon={faExclamationTriangle} className="text-2xl" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Pendientes</h3>
              <p className="text-lg text-gray-600">{facturasResumen.facturasPendientes}</p>
            </div>
          </div>

          {/* Facturas Totales */}
          <div className="bg-white shadow-xl rounded-lg p-3 flex items-center">
            <div className="p-3 bg-yellow-100 text-yellow-400 rounded-full mr-4">
              <FontAwesomeIcon icon={faFileInvoiceDollar} className="text-2xl" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Facturas Totales</h3>
              <p className="text-lg text-gray-600">{facturasResumen.facturasTotales}</p>
            </div>
          </div>

          {/* Total Facturado */}
          <div className="bg-white shadow-xl rounded-lg p-3 flex items-center">
            <div className="p-3 bg-blue-100 text-blue-400 rounded-full mr-4">
              <FontAwesomeIcon icon={faCoins} className="text-2xl" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Total Facturado</h3>
              <p className="text-lg text-gray-600">${facturasResumen.totalFacturado?.toLocaleString('es-CL') || '0'}</p>
            </div>
          </div>

          {/* Total Abonos */}
          <div className="bg-white shadow-xl rounded-lg p-3 flex items-center">
            <div className="p-3 bg-indigo-100 text-indigo-400 rounded-full mr-4">
              <FontAwesomeIcon icon={faPiggyBank} className="text-2xl" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Total Abonos</h3>
              <p className="text-lg text-gray-600">${facturasResumen.totalAbonos.toLocaleString('es-CL')}</p>
            </div>
          </div>

          {/* Total Adeudado */}
          <div className="bg-white shadow-xl rounded-lg p-3 flex items-center">
            <div className="p-3 bg-purple-100 text-purple-400 rounded-full mr-4">
              <FontAwesomeIcon icon={faHandHoldingUsd} className="text-2xl" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Total Adeudado</h3>
              <p className="text-lg text-gray-600">${facturasResumen.totalAdeudado.toLocaleString('es-CL')}</p>
            </div>
          </div>
        </div>
      </section>

       {/* Resumen de Honorarios */}
       <section className='mb-12'>
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Resumen de Honorarios</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Honorarios Pagados este mes */}
          <div className="bg-white shadow-xl rounded-lg p-3 flex items-center">
            <div className="p-3 bg-green-100 text-green-400 rounded-full mr-4">
              <FontAwesomeIcon icon={faCheckCircle} className="text-2xl" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Pagados este mes</h3>
              <p className="text-lg text-gray-600">{honorariosResumen.honorariosPagadasMes}</p>
            </div>
          </div>

          {/* Honorarios Pendientes */}
          <div className="bg-white shadow-xl rounded-lg p-3 flex items-center">
            <div className="p-3 bg-red-100 text-red-400 rounded-full mr-4">
              <FontAwesomeIcon icon={faExclamationTriangle} className="text-2xl" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Pendientes</h3>
              <p className="text-lg text-gray-600">{honorariosResumen.honorariosPendientes}</p>
            </div>
          </div>

          {/* Honorarios Totales */}
          <div className="bg-white shadow-xl rounded-lg p-3 flex items-center">
            <div className="p-3 bg-yellow-100 text-yellow-400 rounded-full mr-4">
              <FontAwesomeIcon icon={faFileInvoiceDollar} className="text-2xl" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Honorarios Totales</h3>
              <p className="text-lg text-gray-600">{honorariosResumen.honorariosTotales}</p>
            </div>
          </div>

          {/* Total Honorarios */}
          <div className="bg-white shadow-xl rounded-lg p-3 flex items-center">
            <div className="p-3 bg-blue-100 text-blue-400 rounded-full mr-4">
              <FontAwesomeIcon icon={faCoins} className="text-2xl" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Total Honorarios</h3>
              <p className="text-lg text-gray-600">${honorariosResumen.totalHonorados.toLocaleString('es-CL')}</p>
            </div>
          </div>

          {/* Total Abonos Honorarios */}
          <div className="bg-white shadow-xl rounded-lg p-3 flex items-center">
            <div className="p-3 bg-indigo-100 text-indigo-400 rounded-full mr-4">
              <FontAwesomeIcon icon={faPiggyBank} className="text-2xl" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Total Abonos Honorarios</h3>
              <p className="text-lg text-gray-600">${honorariosResumen.totalAbonosHonorarios.toLocaleString('es-CL')}</p>
            </div>
          </div>

          {/* Total Adeudado Honorarios */}
          <div className="bg-white shadow-xl rounded-lg p-3 flex items-center">
            <div className="p-3 bg-purple-100 text-purple-400 rounded-full mr-4">
              <FontAwesomeIcon icon={faHandHoldingUsd} className="text-2xl" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Total Adeudado Honorarios</h3>
              <p className="text-lg text-gray-600">${honorariosResumen.totalAdeudadoHonorarios.toLocaleString('es-CL')}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
