// src/components/ProtectedRoute.jsx
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

const ProtectedRoute = ({ isAuthenticated, currentUser, element: Component, requiredRole }) => {
    const { loading } = useContext(UserContext);

    if (loading) {
        // Puedes personalizar este loader
        return <div className="flex items-center justify-center h-screen">Cargando...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    if (requiredRole && currentUser.role !== requiredRole) {
        return <Navigate to="/dashboard" replace />;
    }

    return <Component />;
};

export default ProtectedRoute;