// src/context/UserContext.js
import React, { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true); // Nuevo estado de carga

    return (
        <UserContext.Provider value={{ currentUser, setCurrentUser, loading, setLoading }}>
            {children}
        </UserContext.Provider>
    );
};