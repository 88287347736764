// src/App.js
import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { UserContext, UserProvider } from './context/UserContext';
import Navbar from './components/Navbar';
import Dashboard from './components/Dashboard';
import ClienteManager from './components/ClienteManager';
import FacturaList from './components/FacturaList';
import ClienteForm from './components/ClienteForm';
import AbonosForm from './components/AbonosForm';
import FacturaForm from './components/FacturaForm';
import EditarFactura from './components/EditarFactura';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import UsuarioList from './components/UsuarioList';  
import UsuarioForm from './components/UsuarioForm';
import HonorarioList from './components/HonorarioList';
import HonorarioForm from './components/HonorarioForm';
import AbonarHonorario from './components/AbonarHonorario';
import api from './api';
import DetalleCliente from './components/DetalleCliente';
import CrearFactura from './components/CrearFactura';

const App = () => {
  const { currentUser, setCurrentUser, loading, setLoading } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const isAuthRoute = location.pathname === '/login' || location.pathname === '/';
  const isAuthenticated = Boolean(currentUser);  // Verifica si hay un usuario autenticado

  useEffect(() => {
    if (isAuthenticated && (location.pathname === '/login' || location.pathname === '/')) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, location, navigate]);

  const handleLogin = async (userData) => {
    const { token, usuario } = userData;

    if (!token || !usuario) {
      console.error('Datos incompletos al iniciar sesión');
      return;
    }
    
    localStorage.setItem('token', token); // Guarda el token en el localStorage
    
    const { role, ...otherData } = usuario;

    setCurrentUser({
        ...otherData,
        id: usuario.id, // Usar 'id' en lugar de '_id'
        role: usuario.role,
    });

    navigate('/dashboard');
  };

  const handleLogout = () => {
    setCurrentUser(null);
    localStorage.removeItem('token');
    navigate('/login');
  };

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('token');
      if (token) {
          try {
              const response = await api.get('/usuarios/me', {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  },
              });
              //console.log('Usuario obtenido:', response.data);
              setCurrentUser({
                ...response.data,
                id: response.data.id, // Asegurando que 'id' esté presente
              });
          } catch (error) {
              //console.error('Error al obtener el usuario:', error);
              localStorage.removeItem('token');
          }
      }
      setLoading(false); // Fin de la carga
    };

    fetchUser();
  }, [setCurrentUser, setLoading]);

  if (loading) {
    // Puedes personalizar este loader
    return <div className="flex items-center justify-center h-screen">Cargando...</div>;
  }

  return (
    <div>
      {!isAuthRoute && <Navbar user={currentUser} onLogout={handleLogout} />}
      
      <div className={`container mx-auto ${!isAuthRoute ? 'mt-4' : ''}`}>
        <Routes>
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route path="/" element={<Login onLogin={handleLogin} />} />
          <Route path="/abonosHonorarios/:honorarioId"  element={<ProtectedRoute isAuthenticated={isAuthenticated} currentUser={currentUser} element={AbonarHonorario}/>} /> 
          
          <Route path="/dashboard" element={<ProtectedRoute isAuthenticated={isAuthenticated} currentUser={currentUser} element={Dashboard} />} />
          <Route path="/clientes" element={<ProtectedRoute isAuthenticated={isAuthenticated} currentUser={currentUser} element={ClienteManager} />} />
          <Route path="/facturas" element={<ProtectedRoute isAuthenticated={isAuthenticated} currentUser={currentUser} element={FacturaList} />} />
          <Route path="/nuevo-cliente" element={<ProtectedRoute isAuthenticated={isAuthenticated} currentUser={currentUser} element={ClienteForm} />} />
          <Route path="/abonos" element={<ProtectedRoute isAuthenticated={isAuthenticated} currentUser={currentUser} element={AbonosForm} />} />
          <Route path="/facturas/nueva" element={<ProtectedRoute isAuthenticated={isAuthenticated} currentUser={currentUser} element={CrearFactura} />} />
          <Route path="/facturas/editar/:id" element={<ProtectedRoute isAuthenticated={isAuthenticated} currentUser={currentUser} element={EditarFactura} />} />
          <Route path="/honorarios" element={<ProtectedRoute isAuthenticated={isAuthenticated} currentUser={currentUser} element={HonorarioList} />} />
          <Route path="/honorario-nuevo" element={<ProtectedRoute isAuthenticated={isAuthenticated} currentUser={currentUser} element={HonorarioForm} />} />
          <Route path="/honorarios/editar/:id" element={<ProtectedRoute isAuthenticated={isAuthenticated} currentUser={currentUser} element={HonorarioForm} />} />     
          <Route path="/clientes/detalle/:rut" element={<ProtectedRoute isAuthenticated={isAuthenticated} currentUser={currentUser} element={DetalleCliente} />} />

          {/* Rutas para usuarios */}
          <Route 
            path="/usuarios" 
            element={
              <ProtectedRoute 
                isAuthenticated={isAuthenticated} 
                currentUser={currentUser} 
                element={UsuarioList} 
                requiredRole="ADMIN"
              />
            } 
          />
          <Route 
            path="/mi-perfil" 
            element={
              <ProtectedRoute 
                isAuthenticated={isAuthenticated} 
                currentUser={currentUser} 
                element={UsuarioForm}
              />
            } 
          />

          {/* Ruta 404 */}
          <Route path="*" element={<h1 className="text-center text-red-500 text-xl">Página no encontrada</h1>} />
        </Routes>
      </div>    
    </div>
  );
};

export default function AppWrapper() {
  return (
    <UserProvider>
      <Router>
        <App />
      </Router>
    </UserProvider>
  );
}