import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTrash, faHistory, faPlusCircle, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import api from '../api';

const AbonosForm = () => {
    const [fechaAbono, setFechaAbono] = useState(() => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();
        return `${year}-${month}-${day}`;
    })
    const location = useLocation()
    const { factura } = location.state || {} // obtener la factura desde el estado  
    const [abonos, setAbonos] = useState([]);
    const [montoAbono, setMontoAbono] = useState('')
    const [facturaData, setFacturaData] = useState(null)
    const [cliente, setCliente] = useState(null)
    const [comentario, setComentario] = useState('')
    //const [fechaAbono, setFechaAbono] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        const cargarFactura = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await api.get(`/facturas/${factura._id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`, // Agrega el token aquí
                    },
                })
                setFacturaData(response.data);

                setCliente({
                    nombre: response.data.clienteNombre,
                    rut: response.data.clienteRut
                })

            } catch (error) {
                console.error('Error al cargar la factura:', error);
            }
        };

        if (factura._id) {
            cargarFactura();  // Llamar a la función si existe un ID de factura
        }
    }, [factura?._id])



    // Obtener historial de abonos desde la base de datos al cargar el componente
    const cargarAbonos = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/abonos/${factura._id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Agrega el token aquí
                },
            })
            if (Array.isArray(response.data)) {
                setAbonos(response.data);
            } else {
                console.error('La respuesta no es un array de abonos');
                Swal.fire('Error', 'La respuesta del servidor no es válida.', 'error');
            }
        } catch (error) {
            console.error('Error al cargar los abonos:', error.message);
            Swal.fire('Error', 'No se pudieron cargar los abonos.', 'error');
        }
    }



    useEffect(() => {
        if (factura?._id) {
            //console.log("Cargando abonos para factura ID:", factura._id)
            cargarAbonos();  // Llamar a la función para cargar abonos
        }
    }, [factura?._id])

    const handleMontoAbonoChange = (e) => {
        const value = e.target.value;
        setMontoAbono(value === '' ? '' : parseFloat(value));
    }


    // Funcion par calcular la suma de los abonos
    const calcularTotalAbonos = () => {
        return abonos.reduce((total, abono) => total + (Number(abono.monto) || 0), 0)
    }


    // // Función para verificar si el total abonado alcanza el monto total de la factura
    // const actualizarEstadoFactura = async (nuevoTotalAbonado) => {
    //     let nuevoEstado = 'pendiente';  // Cambia el estado inicial a "pendiente"

    //     if (nuevoTotalAbonado > 0 && nuevoTotalAbonado < factura.monto) {
    //         nuevoEstado = 'abonada'; // Cambia el estado a "abonada" si el monto abonado es mayor a 0 pero menor al total
    //     } else if (nuevoTotalAbonado >= factura.monto) {
    //         nuevoEstado = 'pagada';  // Cambia el estado a "pagada" si el monto abonado es igual o mayor al total
    //     }

    //     try {
    //         // Actualiza la factura con el nuevo total abonado y el estado
    //         const token = localStorage.getItem('token');
    //         const response = await api.put(`/abonar/${factura._id}`, {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${token}`, // Agrega el token aquí
    //             },
    //             body: JSON.stringify({
    //                 total_abonado: nuevoTotalAbonado,
    //                 estado: nuevoEstado,
    //             }),
    //         });

    //         if (response.ok) {
    //             const updatedFactura = await response.json();
    //             console.log("Factura actualizada", updatedFactura);
    //             setFacturaData(updatedFactura);  // Actualiza los datos de la factura en el estado
    //         } else {
    //             console.error('Error al actualizar el estado de la factura');
    //         }
    //     } catch (error) {
    //         console.error('Error al actualizar la factura:', error);
    //     }
    // }


    // Función para agregar un nuevo abono
    const handleAbonoSubmit = async (e) => {
        e.preventDefault();

        if (parseFloat(montoAbono) > 0) {
            try {
                // console.log('ID de la factura:', factura._id);  // Verificar el ID de la factura

                const fechaSeleccionada = new Date(fechaAbono);
                const fechaLocal = new Date(fechaSeleccionada.getTime() + fechaSeleccionada.getTimezoneOffset() * 60000)
                const token = localStorage.getItem('token');
                // Enviar tanto el monto como el ID de la factura
                const response = await api.post(`/abonos/${factura._id}`, {
                    monto: parseFloat(montoAbono),           // El monto que ingresó el usuario
                    factura_id: factura._id,    // El ID de la factura relacionada
                    fecha: fechaLocal,
                    comentario: comentario,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`, // Agrega el token aquí
                    },
                });

                //console.log('Respuesta del backend:', response.data); // Agregado para depuración

                Swal.fire('Éxito!', 'El abono ha sido registrado.', 'success');

                // Actualizar el estado de abonos
                setAbonos([...abonos, response.data])
                const nuevoTotalAbonado = calcularTotalAbonos() + parseFloat(montoAbono)
                // llama a la funcion para actualizar el estado de la factura
                //await actualizarEstadoFactura(nuevoTotalAbonado);
                // Refrescar la lista de abonos
                await cargarAbonos()


                // Resetear los campos del formulario
                setMontoAbono('')  // Limpiar el campo de monto // Reiniciar el campo de monto después de agregar
                setComentario('')
                setFechaAbono(() => {
                    const today = new Date();
                    const day = String(today.getDate()).padStart(2, '0');
                    const month = String(today.getMonth() + 1).padStart(2, '0');
                    const year = today.getFullYear();
                    return `${year}-${month}-${day}`;
                })

            } catch (error) {
                console.error('Error al registrar el abono:', error.response?.data?.message || error.message);
                Swal.fire('Error', error.response?.data?.message || 'Error al registrar el abono.', 'error');
            }
        } else {
            Swal.fire('Error', 'El monto del abono debe ser mayor a 0.', 'error');
        }
    }




    // Función para eliminar la factura
    const eliminarAbono = async (abonoId) => {
        // Primero muestra la alerta de confirmación
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: '¡Sí, eliminar!',
            cancelButtonText: 'Cancelar'
        });

        // Si el usuario confirma, procede a eliminar la factura
        if (result.isConfirmed) {
            try {
                const token = localStorage.getItem('token');
                const response = await api.delete(`/abonos/${abonoId}`, {

                    headers: {
                        'Authorization': `Bearer ${token}`, // Agrega el token aquí
                    },
                })

                //console.log('Respuesta al eliminar abono:', response.data); // Agregado para depuración
                // Muestra la alerta de éxito
                Swal.fire(
                    'Eliminado!',
                    'El abono ha sido eliminado.',
                    'success'
                )
                const abonoEliminado = abonos.find((abono) => abono._id === abonoId);
                setAbonos(abonos.filter((abono) => abono._id !== abonoId)) // remover el abono del estado

                // recalcular el total de abonos
                const nuevoTotalAbonado = calcularTotalAbonos() - (abonoEliminado ? abonoEliminado.monto : 0)

                // Llama a la funcion para actualizar el estado de la factura
                //await actualizarEstadoFactura(nuevoTotalAbonado)

                // Refrescar la lista de abonos
                await cargarAbonos()


            } catch (error) {
                console.error('Error al eliminar el abono:', error.response?.data?.message || error.message)
                Swal.fire('Error', error.response?.data?.message || 'No se pudo eliminar el abono.', 'error')
            }
        }
    }




   // Función para formatear la fecha en DD-MM-YYYY
const formatearFecha = (fechaISO) => {
    if (!fechaISO) return 'Fecha no disponible';
    const fecha = new Date(fechaISO);
    const dia = String(fecha.getDate()).padStart(2, '0');
    const mes = String(fecha.getMonth() + 1).padStart(2, '0');
    const anio = fecha.getFullYear();
    return `${dia}-${mes}-${anio}`;
  }


    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            {factura ? (
                <>
                    <h1 className="text-4xl font-bold mb-8 text-gray-800 text-center">Abonos para Factura #{factura.numero}</h1>

                    <div className="bg-white p-6 rounded-lg shadow-md mb-6">
                        <h2 className="text-2xl font-semibold mb-4 border-b pb-2 text-gray-700">
                            <FontAwesomeIcon icon={faClipboardList} className="mr-2 text-blue-500" />
                            Información del Cliente
                        </h2>
                        {cliente ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <p className="text-lg">
                                        <span className="font-medium text-gray-600">Nombre:</span>{' '}
                                        <span className="font-semibold text-gray-800">{cliente.nombre}</span>
                                    </p>
                                </div>
                                <div>
                                    <p className="text-lg">
                                        <span className="font-medium text-gray-600">RUT:</span>{' '}
                                        <span className="font-semibold text-gray-800">{cliente.rut}</span>
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <p className="text-red-500">Información del cliente no disponible.</p>
                        )}
                    </div>


                    <div className="bg-white p-6 rounded-lg shadow-md mb-6">
                        <h2 className="text-2xl font-semibold mb-4 border-b pb-2 text-gray-700">
                            <FontAwesomeIcon icon={faClipboardList} className="mr-2 text-blue-500" />
                            Resumen de Factura
                        </h2>
                        <div className="space-y-2">
                            <p className="text-lg">
                                <span className="font-medium text-gray-600">Monto Total:</span>{' '}
                                <span className="font-semibold text-gray-800">
                                    ${factura.monto.toLocaleString('es-CL')}
                                </span>
                            </p>
                            <p className="text-lg">
                                <span className="font-medium text-gray-600">Fecha de Emisión:</span>{' '}
                                <span className="font-semibold text-gray-800">
                                    {formatearFecha(factura.fechaEmision)}
                                </span>
                            </p>
                            <p className="text-lg">
                                <span className="font-medium text-gray-600">Saldo Pendiente:</span>{' '}
                                <span className="font-semibold text-red-600">
                                    ${(factura.monto - calcularTotalAbonos()).toLocaleString('es-CL')}
                                </span>
                            </p>

                        </div>
                    </div>


                    <div className="bg-white p-6 rounded-lg shadow-md mb-6">
                        <h2 className="text-2xl font-semibold mb-4 border-b pb-2 text-gray-700">
                            <FontAwesomeIcon icon={faHistory} className="mr-2 text-green-500" />
                            Historial de Abonos
                        </h2>
                        {abonos.length > 0 ? (
                            <ul className="space-y-4">
                                {abonos.map((abono) => (
                                    <li
                                        key={abono._id}
                                        className="border p-4 rounded-md flex justify-between items-center bg-gray-50"
                                    >
                                        <div>
                                            <p className="font-medium text-gray-800">
                                                Monto:{' '}
                                                <span className="font-semibold">
                                                    ${abono.monto.toLocaleString('es-CL')}
                                                </span>
                                            </p>
                                            <p className="text-sm text-gray-500">
                                                Fecha: {formatearFecha(abono.fecha)}
                                            </p>
                                            <p className="font-medium text-gray-800">
                                                Comentario:{' '}
                                                <span className="font-semibold">
                                                    {abono.comentario || 'Sin comentario'}
                                                </span>
                                            </p>
                                        </div>
                                        <button
                                            className="text-red-500 hover:text-red-700 transition-colors"
                                            onClick={() => eliminarAbono(abono._id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} size="lg" />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p className="text-gray-500">No hay abonos registrados.</p>
                        )}
                    </div>

                    <form onSubmit={handleAbonoSubmit} className="bg-white p-6 rounded-lg shadow-md ">
                        <h2 className="text-xl font-semibold mb-4">Nuevo Abono
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2 text-purple-500" />
                        </h2>
                        <div className="mb-4 flex gap-4"> {/* Aquí se añade gap para el espacio entre los inputs */}
                            <div className="flex-1"> {/* Permite que cada input ocupe el mismo espacio disponible */}
                                <label className="block text-sm font-medium text-gray-700">Monto del Abono</label>
                                <input
                                    type="number"
                                    value={montoAbono}
                                    onChange={handleMontoAbonoChange}
                                    className="mt-1 p-3 block w-full border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Ingrese el monto del abono"
                                />
                            </div>
                            <div className="flex-1"> {/* Se repite para el segundo input */}
                                <label className="block text-sm font-medium text-gray-700">Fecha del Abono</label>
                                <input
                                    type="date"
                                    value={fechaAbono}
                                    onChange={(e) => setFechaAbono(e.target.value)}
                                    className="mt-1 p-3 block w-full border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Comentario</label>
                            <textarea
                                value={comentario}
                                onChange={(e) => setComentario(e.target.value)}
                                className="mt-1 p-3 block w-full border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Ingrese un comentario"
                            />
                        </div>
                        <div className="mt-6 flex justify-between">
                            <button type="submit" className="bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 transition-colors">
                                Registrar Abono
                            </button>
                            <button
                                onClick={() => navigate(`/facturas${location.search}`)}
                                className="bg-blue-600 text-white px-6 py-3 rounded-md shadow-lg hover:bg-blue-700 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                Volver
                            </button>
                        </div>
                    </form>
                </>
            ) : (
                <p className="text-red-500">No se ha seleccionado ninguna factura.</p>
            )}
        </div>
    );
};

export default AbonosForm;
