// src/components/HonorarioForm.jsx

import React, { useState, useEffect } from 'react';
import api from '../api';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes } from '@fortawesome/free-solid-svg-icons';

const HonorarioForm = ({ initialData = {}, onSubmit }) => {
  const [clienteSeleccionado, setClienteSeleccionado] = useState(initialData.clienteSeleccionado || null);
  const [fechaEmision, setFechaEmision] = useState(initialData.fechaEmision || '');
  const [fechaPago, setFechaPago] = useState(initialData.fechaPago || '');
  const [estado, setEstado] = useState(initialData.estado || 'pendiente');
  const [monto, setMonto] = useState(initialData.monto || '');
  const [clientes, setClientes] = useState([]);
  const [file, setFile] = useState(null);
  const [resumenCarga, setResumenCarga] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [resultadosCarga, setResultadosCarga] = useState([]); // Nuevo estado
  const [detallesVisibles, setDetallesVisibles] = useState({}); // Para controlar las secciones expandibles
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation(); // Para obtener los parámetros de filtro

  useEffect(() => {
    fetchClientes();
    if (id) {
      fetchHonorario();
    }
  }, [id]);

  const fetchClientes = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await api.get('/clientes', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setClientes(response.data);
    } catch (error) {
      console.error('Error al obtener clientes:', error);
      Swal.fire('Error', 'No se pudieron cargar los clientes.', 'error');
    }
  };

  const fetchHonorario = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await api.get(`/honorarios/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const honorario = response.data;
      setClienteSeleccionado({
        value: honorario.clienteRut,
        label: `${honorario.clienteNombre} - ${honorario.clienteRut}`,
      });
      setFechaEmision(honorario.fechaEmision.slice(0, 10));
      setFechaPago(honorario.fechaPago ? honorario.fechaPago.slice(0, 10) : '');
      setEstado(honorario.estado);
      setMonto(honorario.monto);
    } catch (error) {
      console.error('Error al cargar honorario:', error);
      Swal.fire('Error', 'No se pudo cargar el honorario.', 'error');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!clienteSeleccionado) {
      Swal.fire('Advertencia', 'Seleccione un cliente', 'warning');
      return;
    }
    const token = localStorage.getItem('token');
    const honorarioData = {
      clienteRut: clienteSeleccionado.value,
      fechaEmision,
      fechaPago: fechaPago || null, // Asegurar que sea null si está vacío
      estado,
      monto: parseFloat(monto), // Asegurar que sea un número
    };

    try {
      if (id) {
        await api.put(`/honorarios/${id}`, honorarioData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        Swal.fire('Actualizado', 'Honorario actualizado correctamente', 'success');
      } else {
        await api.post('/honorarios', honorarioData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        Swal.fire('Creado', 'Honorario creado correctamente', 'success');
      }
      navigate(`/honorarios${location.search}`)
    } catch (error) {
      console.error('Error al guardar el honorario:', error);
      Swal.fire('Error', 'No se pudo guardar el honorario. Intente de nuevo más tarde.', 'error');
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setResultadosCarga([]);
  };


  const handleUpload = async () => {
    if (!file) {
      Swal.fire('Advertencia', 'Seleccione un archivo CSV', 'warning');
      return;
    }    

    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('file', file);

      
    setUploading(true);
    setResultadosCarga([]); // Limpiar resultados antes de iniciar la carga
    
      try {
        const response = await api.post('/honorarios/upload', formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
  
        if (response.data.resultados && Array.isArray(response.data.resultados)) {
          setResultadosCarga(response.data.resultados);
          const errores = response.data.resultados.filter((r) => r.estado === 'Error');
  
          if (errores.length > 0) {
            Swal.fire('Carga Completada', 'Se han procesado algunos errores. Revisa los resultados.', 'warning');
          } else {
            Swal.fire('Éxito', 'Carga completada exitosamente.', 'success');
          }
        } else {
          Swal.fire('Éxito', 'Carga completada sin resultados.', 'success');
        }
      } catch (error) {
        console.error('Error al cargar el CSV:', error.response?.data || error.message);
        Swal.fire('Error', error.response?.data?.error || 'Error al cargar el archivo CSV.', 'error');
        setResultadosCarga([]);
      } finally {
        setUploading(false);
        setFile(null);
        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
          fileInput.value = '';
        }
      }
    }


  const clienteOptions = clientes.map((cliente) => ({
    value: cliente.rut,
    label: `${cliente.nombre} - ${cliente.rut}`,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '0.375rem',
      padding: '0.2rem',
      borderColor: 'rgb(209 213 219)',
      '&:hover': {
        borderColor: 'rgb(59 130 246)',
      },
      boxShadow: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'rgb(107 114 128)',
    }),
  };


  // Función para obtener los parámetros de filtro desde la URL
  const getFilterParams = () => {
    const params = new URLSearchParams(location.search);
    const year = params.get('year');
    const month = params.get('month');
    const startDateParam = params.get('startDate');
    const endDateParam = params.get('endDate');
    return { year, month, startDate: startDateParam, endDate: endDateParam };
  }

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-semibold text-center text-gray-800 mb-6">
        {id ? 'Editar Honorario' : 'Gestión de Honorarios'}
      </h1>

       {/* Mostrar sección de carga de CSV solo si no estamos editando */}
       {!initialData.numero && (
        <div className="mb-8 bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Cargar Honorarios desde CSV</h2>
          <div className="flex flex-col sm:flex-row items-center">
            <input
              type="file"
              onChange={handleFileChange}
              accept=".csv"
              className="mb-4 sm:mb-0 sm:mr-4"
              id="fileInput"
            />
            <button
              onClick={handleUpload}
              className="bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 transition duration-200"
              disabled={uploading}
            >
              {uploading ? 'Cargando...' : 'Cargar CSV'}
            </button>
          </div>
        </div>
      )}

      {/* Mostrar resultados de la carga masiva */}
      {resultadosCarga.length > 0 && (
        <div className="mt-8 bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Resultados de la Carga Masiva</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="py-2 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fila</th>
                  <th className="py-2 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Estado</th>
                  <th className="py-2 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Detalles</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {resultadosCarga.map((resultado, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="py-2 px-4 whitespace-nowrap">{resultado.fila}</td>
                    <td
                      className={`py-2 px-4 whitespace-nowrap ${
                        resultado.estado === 'Éxito' ? 'text-green-500' : 'text-red-500'
                      }`}
                    >
                      {resultado.estado}
                    </td>
                    <td className="py-2 px-4">
                      {resultado.detalles || 'N/A'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Formulario para crear o editar honorario */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">
          {id ? 'Editar Honorario' : 'Crear Nuevo Honorario'}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Cliente */}
            <div>
              <label className="block text-gray-700 mb-2">Cliente</label>
              <Select
                options={clienteOptions}
                value={clienteSeleccionado}
                onChange={setClienteSeleccionado}
                placeholder="Seleccione un cliente"
                isClearable
                styles={customStyles}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            {/* Fecha de Emisión */}
            <div>
              <label className="block text-gray-700 mb-2">Fecha de Emisión</label>
              <input
                type="date"
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={fechaEmision}
                onChange={(e) => setFechaEmision(e.target.value)}
                required
              />
            </div>
            {/* Fecha de Pago */}
            <div>
              <label className="block text-gray-700 mb-2">Fecha de Pago (opcional)</label>
              <input
                type="date"
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={fechaPago}
                onChange={(e) => setFechaPago(e.target.value)}
              />
            </div>
            {/* Estado */}
            <div>
              <label className="block text-gray-700 mb-2">Estado</label>
              <select
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={estado}
                onChange={(e) => setEstado(e.target.value)}
                required
              >
                <option value="pendiente">Pendiente</option>
                <option value="pagada">Pagada</option>

              </select>
            </div>
            {/* Monto */}
            <div>
              <label className="block text-gray-700 mb-2">Monto</label>
              <input
                type="number"
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={monto}
                onChange={(e) => setMonto(e.target.value)}
                required
                min="0"
                step="0.01"
              />
            </div>
          </div>
          <div className="mt-6 flex justify-between">
            <button
              type="submit"
              className="bg-green-600 text-white px-6 py-3 rounded-md shadow-lg hover:bg-green-700 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500bg-green-500 text-white p-3 rounded-md hover:bg-green-600 transition duration-200"
            >
              {id ? 'Actualizar Honorario' : 'Guardar Honorario'}
            </button>
            <button
              type="button"
              onClick={() => navigate(`/honorarios${location.search}`)}
              className="bg-blue-600 text-white px-6 py-3 rounded-md shadow-lg hover:bg-blue-700 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Volver
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HonorarioForm;