// src/components/AbonarHonorario.jsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faHistory, faPlusCircle, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import api from '../api';

const AbonarHonorario = () => {
    const { honorarioId } = useParams(); // ID del honorario
    const navigate = useNavigate();
    const location = useLocation();


    // Estado para almacenar el honorario
    const [honorario, setHonorario] = useState(null);

    // estado para almacenar el cliente
    const [cliente, setCliente] = useState(null);

    // Estados para los abonos
    const [abonos, setAbonos] = useState([]);

    // Estados para el formulario de abono
    const [montoAbono, setMontoAbono] = useState('');
    const [fechaAbono, setFechaAbono] = useState(() => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();
        return `${year}-${month}-${day}`;
    })
    const [comentario, setComentario] = useState('');

    // Verificar si honorarioId está presente
    useEffect(() => {
        if (!honorarioId) {
            Swal.fire('Error', 'ID de honorario no proporcionado.', 'error');
            navigate('/honorarios');
            return;
        }
        fetchHonorario();
        fetchAbonos();
    }, [honorarioId, navigate])

    // Función para obtener los detalles del honorario
    const fetchHonorario = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await api.get(`/honorarios/${honorarioId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            setHonorario(response.data);
            fetchCliente(response.data.clienteRut);
        } catch (error) {
            console.error('Error al cargar honorario:', error);
            Swal.fire('Error', 'No se pudo cargar el honorario.', 'error');
            navigate(`/honorarios${location.search}`);
        }
    };

    // Función para obtener los abonos del honorario
    const fetchAbonos = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/abonosHonorarios/${honorarioId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            setAbonos(response.data);
        } catch (error) {
            console.error('Error al cargar los abonos de honorario:', error);
            Swal.fire('Error', 'No se pudieron cargar los abonos', 'error');
        }
    };


    // **Nueva Función para Obtener los Datos del Cliente**
    const fetchCliente = async (rut) => {
        const token = localStorage.getItem('token');
        try {
            const response = await api.get(`/clientes/rut/${rut}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            setCliente(response.data);
        } catch (error) {
            console.error('Error al cargar la información del cliente:', error);
            Swal.fire('Error', 'No se pudo cargar la información del cliente.', 'error');
        }
    }


    // Función para calcular el total de abonos
    const calcularTotalAbonos = () => {
        return abonos.reduce((total, abono) => total + abono.monto, 0);
    };


    // Función para manejar el envío del formulario de abono
    const handleAbonoSubmit = async (e) => {
        e.preventDefault();
        if (!montoAbono || !fechaAbono) {
            Swal.fire('Advertencia', 'Por favor, complete todos los campos obligatorios.', 'warning');
            return;
        }
        const token = localStorage.getItem('token');
        const abonoData = {
            monto: parseFloat(montoAbono),
            fecha: fechaAbono,
            comentario,
        };
        try {
            await api.post(`/abonosHonorarios/${honorarioId}`, abonoData, {
                headers: { Authorization: `Bearer ${token}` },
            });


            Swal.fire('Éxito', 'Abono realizado correctamente.', 'success');
            setMontoAbono('');
            setFechaAbono('');
            setComentario('');
            fetchAbonos();
            fetchHonorario(); // Refrescar datos del honorario
        } catch (error) {
            console.error('Error al realizar abono:', error);
            if (error.response && error.response.data && error.response.data.message) {
                Swal.fire('Error', error.response.data.message, 'error');
            } else {
                Swal.fire('Error', 'No se pudo realizar el abono.', 'error');
            }
        }
    }

    // Función para eliminar un abono
    const eliminarAbono = async (abonoId) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción no se puede deshacer.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const token = localStorage.getItem('token');
                    await api.delete(`/abonosHonorarios/${abonoId}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    Swal.fire('Eliminado', 'El abono ha sido eliminado.', 'success');

                    // Refrescar los datos del honorario y los abonos
                    fetchHonorario();
                    fetchAbonos();
                } catch (error) {
                    console.error('Error al eliminar abono:', error);
                    Swal.fire('Error', 'No se pudo eliminar el abono.', 'error');
                }
            }
        });
    }

    // Función para formatear la fecha en DD-MM-YYYY
    const formatearFecha = (fechaISO) => {
        if (!fechaISO) return 'N/A';
        const fecha = new Date(fechaISO);
        const dia = String(fecha.getUTCDate()).padStart(2, '0');
        const mes = String(fecha.getUTCMonth() + 1).padStart(2, '0'); // Los meses son base 0
        const anio = fecha.getUTCFullYear();
        return `${dia}-${mes}-${anio}`;
    }

    if (!honorario) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p>Cargando honorario...</p>
            </div>
        )
    }

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <h1 className="text-4xl font-bold mb-8 text-gray-800 text-center">Abonos para Honorarios</h1>
            {/* Información del Cliente */}
            <div className="bg-white p-6 rounded-lg shadow-md mb-6">
                <h2 className="text-2xl font-semibold mb-4 border-b pb-2 text-gray-700">Información del Cliente</h2>
                {cliente ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <p className="text-lg"><span className="font-medium text-gray-600">Nombre:</span> <span className="font-semibold text-gray-800">{cliente.nombre}</span></p>
                        </div>
                        <div>
                            <p className="text-lg"><span className="font-medium text-gray-600">RUT:</span> <span className="font-semibold text-gray-800">{cliente.rut}</span></p>
                        </div>

                    </div>
                ) : (
                    <p className="text-red-500">Información del cliente no disponible.</p>
                )}
            </div>

            {/* Resumen de Honorario */}
            <div className="bg-white p-6 rounded-lg shadow-md mb-6">
                <h2 className="text-2xl font-semibold mb-4 border-b pb-2 text-gray-700">
                    <FontAwesomeIcon icon={faClipboardList} className="mr-2 text-blue-500" />
                    Resumen de Honorario</h2>
                <div className="space-y-2">
                    <p className="text-lg"><span className="font-medium text-gray-600">Monto Total:</span> <span className="font-semibold text-gray-800">${honorario.monto.toLocaleString('es-CL')}</span></p>
                    <p className="text-lg"><span className="font-medium text-gray-600">Fecha de Emisión:</span> <span className="font-semibold text-gray-800">{formatearFecha(honorario.fechaEmision)}</span></p>
                    <p className="text-lg"><span className="font-medium text-gray-600">Saldo Pendiente:</span> <span className="font-semibold text-red-600">${(honorario.monto - calcularTotalAbonos()).toLocaleString('es-CL')}</span></p>
                    <p className="text-lg"><span className="font-medium text-gray-600">Estado:</span> <span className="font-semibold text-gray-800">{honorario.estado}</span></p>
                </div>
            </div>

            {/* Historial de Abonos */}
            <div className="bg-white p-6 rounded-lg shadow-md mb-6">
                <h2 className="text-2xl font-semibold mb-4 border-b pb-2 text-gray-700">
                    <FontAwesomeIcon icon={faHistory} className="mr-2 text-green-500" />
                    Historial de Abonos
                </h2>
                {abonos.length > 0 ? (
                    <ul className="space-y-4">
                        {abonos.map((abono) => (
                            <li key={abono._id} className="border p-4 rounded-md flex justify-between items-center bg-gray-50">
                                <div>
                                    <p className="font-medium text-gray-800">Monto: <span className="font-semibold">${abono.monto.toLocaleString('es-CL')}</span></p>
                                    <p className="text-sm text-gray-500">Fecha: {formatearFecha(abono.fecha)}</p>
                                    <p className="font-medium text-gray-800">Comentario: <span className="font-semibold">{abono.comentario || 'Sin comentario'}</span></p>
                                </div>
                                <button
                                    className="text-red-500 hover:text-red-700 transition-colors"
                                    onClick={() => eliminarAbono(abono._id)}
                                >
                                    <FontAwesomeIcon icon={faTrash} size="lg" />
                                </button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-gray-500">No hay abonos registrados.</p>
                )}
            </div>

            {/* Formulario para registrar un nuevo abono */}

            <form onSubmit={handleAbonoSubmit} className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold mb-4 border-b pb-2 text-gray-700">
                    <FontAwesomeIcon icon={faPlusCircle} className="mr-2 text-purple-500" />
                    Nuevo Abono
                </h2>
                <div mb-4 flex gap-4>
                    <div flex-1>
                        <label className="block text-sm font-medium text-gray-700">Monto del Abono</label>
                        <input
                            type="number"
                            value={montoAbono}
                            onChange={(e) => setMontoAbono(e.target.value)}
                            className="mt-1 p-3 block w-full border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            required
                            min="0"
                            step="0.01"
                            placeholder="Ingrese el monto del abono"
                        />
                    </div>
                    <div flex-1>
                        <label className="block text-sm font-medium text-gray-700">Fecha del Abono</label>
                        <input
                            type="date"
                            value={fechaAbono}
                            onChange={(e) => setFechaAbono(e.target.value)}
                            className="mt-1 p-3 block w-full border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                </div>

                <div>
                    <label className="block text-gray-700">Comentario</label>
                    <textarea
                        value={comentario}
                        onChange={(e) => setComentario(e.target.value)}
                        className="w-full mt-1 p-2 border rounded"
                        placeholder="Ingrese un comentario opcional"
                    />
                </div>
                <div className="mt-6 flex justify-between">
                    <button
                        type="submit"
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                    >
                        Registrar Abono
                    </button>
                    {/* Botón Volver */}

                    <button
                        onClick={() => navigate(`/honorarios${location.search}`)}
                        className="bg-blue-600 text-white px-6 py-3 rounded-md shadow-lg hover:bg-blue-700 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Volver al Listado
                    </button>
                </div>
            </form>



        </div>
    );
};

export default AbonarHonorario;