// src/components/FacturaForm.jsx

import React, { useState, useEffect } from 'react';
import api from '../api';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const FacturaForm = ({ initialData = {}, onSubmit, onCancel}) => {
  const [numero, setNumero] = useState(initialData.numero || '');
  const [clienteSeleccionado, setClienteSeleccionado] = useState(initialData.clienteSeleccionado || null);
  const [fechaEmision, setFechaEmision] = useState(initialData.fechaEmision || '');
  const [fechaPago, setFechaPago] = useState(initialData.fechaPago || '');
  const [estado, setEstado] = useState(initialData.estado || 'pendiente');
  const [monto, setMonto] = useState(initialData.monto || '');
  const [clientes, setClientes] = useState([]);
  const [file, setFile] = useState(null);
  const [resultadosCarga, setResultadosCarga] = useState([]);
  const [detallesVisibles, setDetallesVisibles] = useState({});
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchClientes();
  }, []);

  const fetchClientes = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await api.get('/clientes', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setClientes(response.data);
    } catch (error) {
      console.error('Error al cargar clientes:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!clienteSeleccionado) {
      Swal.fire('Advertencia', 'Seleccione un cliente', 'warning');
      return;
    }
    if (onSubmit) {
      await onSubmit({
        numero,
        clienteSeleccionado,
        fechaEmision,
        fechaPago,
        estado,
        monto,
      });
    } else {
      const token = localStorage.getItem('token');
      try {
        await api.post(
          '/facturas',
          {
            numero,
            clienteRut: clienteSeleccionado.value,
            fechaEmision,
            fechaPago: fechaPago || null,
            estado,
            monto,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        Swal.fire('Éxito', 'Factura creada correctamente', 'success');
        // Limpiar el formulario
        setNumero('');
        setClienteSeleccionado(null);
        setFechaEmision('');
        setFechaPago('');
        setEstado('pendiente');
        setMonto('');
      } catch (error) {
        console.error('Error al crear factura:', error);
        Swal.fire('Error', 'No se pudo crear la factura', 'error');
      }
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setResultadosCarga([]); // Limpiar resultados anteriores
  };

  const handleUpload = async () => {
    if (!file) {
      Swal.fire('Advertencia', 'Seleccione un archivo CSV', 'warning');
      return;
    }
    setUploading(true);
    setResultadosCarga([]); // Limpiar resultados antes de iniciar la carga

    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post('/facturas/upload', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.resultados) {
        setResultadosCarga(response.data.resultados);
      } else {
        setResultadosCarga([]);
        Swal.fire('Éxito', 'Carga completada sin resultados.', 'success');
      }
    } catch (error) {
      console.error('Error al cargar el CSV:', error.response?.data || error.message);
      Swal.fire('Error', error.response?.data?.error || 'Error al cargar el archivo CSV.', 'error');
      setResultadosCarga([]);
    } finally {
      setUploading(false);
      setFile(null); // Resetear el input de archivo
    }
  }


  const toggleDetalles = (index) => {
    setDetallesVisibles((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const clienteOptions = clientes.map((cliente) => ({
    value: cliente.rut,
    label: `${cliente.nombre} - ${cliente.rut}`,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '0.375rem',
      padding: '0.2rem',
      borderColor: 'rgb(209 213 219)',
      '&:hover': {
        borderColor: 'rgb(59 130 246)',
      },
      boxShadow: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'rgb(107 114 128)',
    }),
  }


 
 
  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-semibold text-center text-gray-800 mb-6">
        {initialData.numero ? 'Editar Factura' : 'Gestión de Facturas'}
      </h1>

      {/* Mostrar sección de carga de CSV solo si no estamos editando */}
      {!initialData.numero && (
        <div className="mb-8 bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Cargar Facturas desde CSV</h2>
          <div className="flex flex-col sm:flex-row items-center">
            <input
              type="file"
              onChange={handleFileChange}
              accept=".csv"
              className="mb-4 sm:mb-0 sm:mr-4"
            />
            <button
              onClick={handleUpload}
              className="bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 transition duration-200"
              disabled={uploading}
            >
              {uploading ? 'Cargando...' : 'Cargar CSV'}
            </button>
          </div>
        </div>
      )}


      {resultadosCarga.length > 0 && (
        <div className="mt-8 bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Resultados de la Carga Masiva</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="py-2 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fila</th>
                  <th className="py-2 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Estado</th>
                  <th className="py-2 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Detalles</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {resultadosCarga.map((resultado, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="py-2 px-4 whitespace-nowrap">{resultado.fila}</td>
                    <td className={`py-2 px-4 whitespace-nowrap ${resultado.estado === 'Éxito' ? 'text-green-500' : 'text-red-500'}`}>
                      {resultado.estado}
                    </td>
                    <td className="py-2 px-4">
                      {resultado.detalles || 'N/A'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}




      {/* Formulario para crear o editar factura */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">
          {initialData.numero ? 'Editar Factura' : 'Crear Nueva Factura'}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Número de Factura */}
            <div>
              <label className="block text-gray-700 mb-2">Número de Factura</label>
              <input
                type="text"
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={numero}
                onChange={(e) => setNumero(e.target.value)}
                required
              />
            </div>
            {/* Cliente */}
            <div>
              <label className="block text-gray-700 mb-2">Cliente</label>
              <Select
                options={clienteOptions}
                value={clienteSeleccionado}
                onChange={setClienteSeleccionado}
                placeholder="Seleccione un cliente"
                isClearable
                styles={customStyles}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>
            {/* Fecha de Emisión */}
            <div>
              <label className="block text-gray-700 mb-2">Fecha de Emisión</label>
              <input
                type="date"
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={fechaEmision}
                onChange={(e) => setFechaEmision(e.target.value)}
                required
              />
            </div>
            {/* Fecha de Pago */}
            <div>
              <label className="block text-gray-700 mb-2">Fecha de Pago (opcional)</label>
              <input
                type="date"
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={fechaPago}
                onChange={(e) => setFechaPago(e.target.value)}
              />
            </div>
            {/* Estado */}
            <div>
              <label className="block text-gray-700 mb-2">Estado</label>
              <select
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={estado}
                onChange={(e) => setEstado(e.target.value)}
                required
              >
                <option value="pendiente">Pendiente</option>
                <option value="pagada">Pagada</option>

              </select>
            </div>
            {/* Monto */}
            <div>
              <label className="block text-gray-700 mb-2">Monto</label>
              <input
                type="number"
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={monto}
                onChange={(e) => setMonto(e.target.value)}
                required
              />
            </div>
          </div>
           {/* Botones de Acción */}
          <div className="mt-6 flex justify-between">
            <button
              type="submit"
              className=" bg-green-500 text-white p-3 rounded-md hover:bg-green-600 transition duration-200"
            >
              {initialData.numero ? 'Actualizar Factura' : 'Guardar Factura'}
            </button>
            <button
              type="button"
              onClick={onCancel}
              // onClick={() => navigate(`/facturas${location.search}`)}
              className="bg-blue-600 text-white px-6 py-3 rounded-md shadow-lg hover:bg-blue-700 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Volver
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FacturaForm;