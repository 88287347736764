import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import { UserContext } from '../context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

const Navbar = ({ user, onLogout }) => {

    const { currentUser } = useContext(UserContext);
    const { avatar, nombre, role } = currentUser || {}
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    }

    return (
        <nav className="bg-white shadow-md">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <Link to="/" className="text-2xl font-bold text-gray-800">OH-Facturacion</Link>
                        </div>
                        <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                            {/* <Link to="/" className="text-gray-600 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium">
                                Inicio
                            </Link> */}
                            <Link to="/dashboard" className="text-gray-600 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium">
                                Dashboard
                            </Link>
                            <Link to="/clientes" className="text-gray-600 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium">
                                Clientes
                            </Link>
                            {/* <Link to="/DetalleClientes" className="text-gray-600 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium">
                                Detalle Clientes
                            </Link> */}
                            <Link to="/facturas" className="text-gray-600 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium">
                                Facturas
                            </Link>
                            <Link to="/honorarios" className="text-gray-600 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium">
                                Honorarios
                            </Link>
                            {/* Muestra la opcion Usuarios solo si el rol es ADMIN */}
                            {user?.role === 'ADMIN' && (
                                <Link to="/usuarios" className="text-gray-600 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium">
                                    Usuarios
                                </Link>
                            )}

                        </div>
                    </div>

                    <div className='flex items-center space-x-4'>
                        {/* <span className='text-gray-600'> Hola, {user?.nombre}</span> */}

                        <div className="flex items-center space-x-4">
                            {/* Nombre de usuario y perfil con dropdown */}
                            <div className="relative">
                            <button onClick={toggleDropdown} className="flex items-center text-gray-600 hover:text-gray-800">
                                    <span className="mr-2 hidden sm:block">{nombre}</span>
                                    <div className="w-10 h-10 rounded-full overflow-hidden">
                                        {avatar ? (
                                            <img src={`/assets/avatars/${avatar}`} alt="Avatar" className="w-full h-full object-cover" />
                                        ) : (
                                            <FontAwesomeIcon icon={faUserCircle} className="text-2xl text-gray-500" />
                                        )}
                                    </div>
                                </button>
                                {/* Dropdown para opciones de perfil */}
                                {isDropdownOpen && (
                                    <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg">
                                        <Link to="/mi-perfil" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                                            Mi Perfil
                                        </Link>
                                        <button
                                            onClick={onLogout}
                                            className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                                        >
                                            Cerrar sesión
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
