import React, { useEffect, useState } from 'react';
//import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faMagnifyingGlass, faPlus, faUpload, faFileCsv, faTimes, faEye } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { set } from 'mongoose';
import '../App.css'
import api from '../api';

const ClienteManager = ({ currentUser }) => {
    const [clientes, setClientes] = useState([]);
    const [message, setMessage] = useState('');
    const [file, setFile] = useState(null) //    estado para el archivo CSV
    const [fileName, setFileName] = useState('');
    const [searchTerm, setSearchTerm] = useState(''); // Buscador
    const [sortField, setSortField] = useState('nombre'); // Campo de ordenación
    const [sortOrder, setSortOrder] = useState('asc'); // Orden ascendente o descendente
    const [selectedCliente, setSelectedCliente] = useState(null); // Cliente seleccionado para editar
    const navigate = useNavigate();
    const { id, role } = currentUser || {}
    const [resumenCarga, setResumenCarga] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const limit = 25;
    const [error, setError] = useState(null);
    

    // Obtener clientes con saldos pendientes
    const fetchClientes = async (page = 1) => {
        const token = localStorage.getItem('token'); // Obtener el token del localStorage
        setLoading(true);
        try {
            const response = await api.get('/clientes/paginated', {
                params: { page, limit, searchTerm, sortField, sortOrder },
                headers: {
                    Authorization: `Bearer ${token}`, // Incluir el token en los headers
                },
            });
            //console.log('Respuesta de la API:', response.data); // Debugging

            const { data: clientes, total, page: current, limit: perPage } = response.data;

            // const clientesConSaldo = await Promise.all(
            //     clientes.map(async (cliente) => {
            //         try {
            //             const facturas = await api.get(`/facturas/rut/${cliente.rut}`, {
            //                 headers: {
            //                     Authorization: `Bearer ${token}`, // Incluir el token aquí también
            //                 },
            //             });

            //             const facturasPendientes = facturas.data.filter(
            //                 (factura) => factura.estado === 'pendiente' || factura.estado === 'abonada'
            //             );
            //             const saldoPendiente = facturasPendientes.reduce(
            //                 (acc, factura) => acc + (factura.monto - (factura.total_abonado || 0)),
            //                 0
            //             );
            //             const totalAbonado = facturasPendientes.reduce(
            //                 (acc, factura) => acc + (factura.total_abonado || 0),
            //                 0
            //             );

            //             return {
            //                 ...cliente,
            //                 saldoPendiente,
            //                 cantidadPendiente: facturasPendientes.length,
            //                 totalAbonado,
            //             };
            //         } catch (error) {
            //             if (error.response && error.response.status === 404) {
            //                 // No se encontraron facturas para este cliente, asignar valores predeterminados
            //                 return {
            //                     ...cliente,
            //                     saldoPendiente: 0,
            //                     cantidadPendiente: 0,
            //                     totalAbonado: 0,
            //                 };
            //             } else {
            //                 console.error(
            //                     `Error al obtener facturas para el cliente ${cliente.rut}:`,
            //                     error.response ? error.response.data : error.message
            //                 );
            //                 return {
            //                     ...cliente,
            //                     saldoPendiente: 0,
            //                     cantidadPendiente: 0,
            //                     totalAbonado: 0,
            //                 };
            //             }
            //         }
            //     })
            // )
            setClientes(clientes);
            setTotalPages(Math.ceil(total / perPage || limit));
            setCurrentPage(current);
            setError(null);
            //console.log(`Total Clientes: ${total}, Clientes por Página: ${perPage}, Total Páginas: ${Math.ceil(total / perPage)}`);
        } catch (error) {
            console.error(`Error al obtener clientes: ${error.message}`);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (currentPage > totalPages && totalPages > 0) {
            setCurrentPage(totalPages);
        } else {
            fetchClientes(currentPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, totalPages, searchTerm, sortField, sortOrder, id, role]);


    // Paginación
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        }
    };


    // Manejar la carga del archivo csv
    const handleFileChange = (e) => {
        setFile(e.target.files[0]); // Guardar el archivo seleccionado
        setFileName(e.target.files[0].name); // Mostrar el nombre del archivo seleccionado
    };


    // Eliminar cliente
    const handleDelete = (id) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción no se puede deshacer.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const token = localStorage.getItem('token');
                    await api.delete(`/clientes/${id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setClientes(clientes.filter((cliente) => cliente._id !== id));
                    Swal.fire('Eliminado', 'El cliente ha sido eliminado.', 'success');
                } catch (err) {
                    console.error(err);
                    Swal.fire('Error', 'No se pudo eliminar el cliente.', 'error');
                }
            }
        });
    }




    // Enviar el archivo al backend
    const handleUpload = async () => {
        if (!file) {
            Swal.fire('Advertencia', 'Seleccione un archivo CSV', 'warning');
            return;
        }
        setUploading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await api.post('/clientes/upload', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Actualizar la lista de clientes
            await fetchClientes();

            // Mostrar resumen de la carga
            setResumenCarga(response.data);
            setModalIsOpen(true);

            Swal.fire('Éxito', 'Carga completada. Vea el resumen en el Modal.', 'success');
            setFile(null);
            setFileName('');
        } catch (error) {
            console.error('Error al cargar clientes:', error);
            Swal.fire('Error', 'No se pudieron cargar los clientes', 'error');
        } finally {
            setUploading(false);
        }
    };


    const closeModal = () => {
        setModalIsOpen(false);
        setResumenCarga(null);
    };



    // Función para actualizar el cliente
    const handleUpdateCliente = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        try {
            const response = await api.put(
                `/clientes/${selectedCliente._id}`,
                selectedCliente,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            // Actualizar la lista de clientes
            await fetchClientes();
            // Mostrar alerta de éxito
            Swal.fire('Actualizado', 'Cliente actualizado correctamente', 'success');
            // Cerrar el modal
            setSelectedCliente(null);
        } catch (error) {
            console.error('Error al actualizar el cliente:', error);
            Swal.fire('Error', 'No se pudo actualizar el cliente.', 'error');
        }
    };


    // // Buscar clientes por nombre o rut
    // const filteredClientes = clientes.filter(cliente =>
    //     cliente.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     cliente.rut.toLowerCase().includes(searchTerm.toLowerCase())
    // );

     // Manejar cambio en el término de búsqueda
     const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Resetear a la primera página cuando cambia la búsqueda
    };


    // Ordenar clientes por nombre o saldo pendiente
    // const sortedClientes = [...filteredClientes].sort((a, b) => {
    //     const isAsc = sortOrder === 'asc';
    //     if (sortField === 'saldoPendiente') {
    //         // Asegurarse de que saldoPendiente sea un número
    //         const saldoA = parseFloat(a.saldoPendiente.toString().replace(/\./g, '').replace(/,/g, ''));
    //         const saldoB = parseFloat(b.saldoPendiente.toString().replace(/\./g, '').replace(/,/g, ''));

    //         return isAsc ? saldoA - saldoB : saldoB - saldoA;
    //     } else {
    //         return isAsc ? a.nombre.localeCompare(b.nombre) : b.nombre.localeCompare(a.nombre);
    //     }
    // })

    // Manejar la edición de cliente
    const handleEditClick = (cliente) => setSelectedCliente(cliente);

    const handleSortClick = (field) => {
        if (sortField === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortOrder('asc');
        }
        setCurrentPage(1); // Resetear a la primera página cuando cambia el orden
    }


    const handleViewDetails = (rut) => {
        navigate(`/clientes/detalle/${rut}`);
    }



     // Generar los botones de paginación
     const renderPagination = () => {
        if (totalPages <= 1) return null; // No mostrar paginación si solo hay una página
    
        const pageNumbers = [];
        const maxPageButtons = 5; // Número máximo de botones a mostrar
    
        let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
        let endPage = startPage + maxPageButtons - 1;
    
        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(1, endPage - maxPageButtons + 1);
        }
    
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }
    
        return (
            <div className="flex justify-center items-center mt-4 space-x-2">
                <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1 || loading}
                    className={`w-8 h-8 flex items-center justify-center rounded-full ${currentPage === 1 || loading
                        ? 'bg-gray-300 cursor-not-allowed'
                        : 'bg-blue-500 text-white hover:bg-blue-600'
                        }`}
                >
                    {'<'}
                </button>
                {pageNumbers.map((number) => (
                    <button
                        key={number}
                        onClick={() => setCurrentPage(number)}
                        className={`w-8 h-8 flex items-center justify-center rounded-full ${currentPage === number
                            ? 'bg-blue-700 text-white'
                            : 'bg-blue-500 text-white hover:bg-blue-600'
                            }`}
                    >
                        {number}
                    </button>
                ))}
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages || loading}
                    className={`w-8 h-8 flex items-center justify-center rounded-full ${currentPage === totalPages || loading
                        ? 'bg-gray-300 cursor-not-allowed'
                        : 'bg-blue-500 text-white hover:bg-blue-600'
                        }`}
                >
                    {'>'}
                </button>
            </div>
        );
    };



    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <h1 className="text-2xl font-bold mb-6 text-center">Lista de Clientes</h1>

            <div className="flex justify-end mb-4">
                <button
                    onClick={() => navigate('/nuevo-cliente')}
                    className="bg-green-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-green-600 mb-4"
                >
                    <FontAwesomeIcon icon={faPlus} className="text-lg" />
                </button>
            </div>
            {message && <p className="text-green-500 mb-4">{message}</p>}

            {/* Buscador de clientes */}
            <div className="relative mb-4">
                <input
                    type="text"
                    placeholder="Buscar por nombre o RUT"
                    className="border border-gray-300 p-2 pl-10 rounded-full w-full"
                    value={searchTerm}
                    //onChange={(e) => setSearchTerm(e.target.value)}
                    onChange={handleSearchChange}
                />
                <span className="absolute right-3 top-2">
                    <FontAwesomeIcon icon={faMagnifyingGlass} className="text-gray-400" />
                </span>
            </div>

            {/* Formulario para la carga masiva de clientes */}
            <div className="mb-8 bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">Carga masiva</h2>

                {/* Estilo del input file */}
                <div className="flex items-center space-x-4">
                    <label className="bg-gray-200 text-gray-700 rounded-full px-4 py-2 cursor-pointer hover:bg-gray-300">
                        <FontAwesomeIcon icon={faFileCsv} className="mr-2" />
                        Seleccionar CSV
                        <input
                            type="file"
                            accept=".csv"
                            onChange={handleFileChange}
                            className="hidden"
                        />
                    </label>

                    {/* Botón para cargar el archivo */}
                    <button
                        onClick={handleUpload}
                        className="bg-blue-500 text-white rounded-full px-4 py-2 flex items-center hover:bg-blue-600"
                        disabled={uploading}
                    >
                        <FontAwesomeIcon icon={faUpload} className="mr-2" />
                        {uploading ? 'Cargando...' : 'Cargar CSV'}
                    </button>
                </div>
                {fileName && <span className="text-gray-600">{fileName}</span>}
            </div>







            {/* Modal para mostrar el resumen de la carga */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Resumen de la Carga"
                className="modal"
                overlayClassName="modal-overlay"
            >
                <div className="relative p-6 bg-white rounded-lg shadow-lg">
                    <button onClick={closeModal} className="absolute top-2 right-2 text-gray-600 hover:text-gray-800">
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <h2 className="text-2xl font-semibold mb-4">Resumen de la Carga</h2>
                    <div className="overflow-y-auto max-h-96">
                        {/* Resultados exitosos */}
                        {resumenCarga?.resultados.length > 0 && (
                            <div className="mt-4">
                                <h3 className="font-medium text-green-600">Clientes Creados:</h3>
                                <ul className="list-disc list-inside">
                                    {resumenCarga.resultados.map((resultado, idx) => (
                                        <li key={idx}>
                                            Línea {resultado.linea}: {resultado.mensaje}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {/* Advertencias */}
                        {resumenCarga?.advertencias.length > 0 && (
                            <div className="mt-4">
                                <h3 className="font-medium text-yellow-600">Advertencias:</h3>
                                <ul className="list-disc list-inside">
                                    {resumenCarga.advertencias.map((advertencia, idx) => (
                                        <li key={idx}>
                                            Línea {advertencia.linea}: {advertencia.mensaje}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {/* Errores */}
                        {resumenCarga?.errores.length > 0 && (
                            <div className="mt-4">
                                <h3 className="font-medium text-red-600">Errores:</h3>
                                <ul className="list-disc list-inside">
                                    {resumenCarga.errores.map((error, idx) => (
                                        <li key={idx}>
                                            Línea {error.linea}: {error.mensaje}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>










            {/* Tabla de clientes */}
            
                <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 px-6 text-left cursor-pointer" onClick={() => handleSortClick('nombre')}>
                                Nombre {sortField === 'nombre' && (sortOrder === 'asc' ? '▲' : '▼')}
                            </th>
                            <th className="py-3 px-6 text-left">RUT</th>
                            <th className="py-3 px-6 text-left cursor-pointer" onClick={() => handleSortClick('saldoPendiente')}>
                                Saldo Pendiente {sortField === 'saldoPendiente' && (sortOrder === 'asc' ? '▲' : '▼')}
                            </th>
                            <th className="py-3 px-6 text-left">Abonos</th>
                            <th className="py-3 px-6 text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                    {loading ? (
                        <tr>
                            <td colSpan="6" className="text-center py-4">Cargando...</td>
                        </tr>
                    ) : error ? (
                        <tr>
                            <td colSpan="6" className="text-center py-4 text-red-500">{error}</td>
                        </tr>
                    ) : clientes.length === 0 ? (
                        <tr>
                            <td colSpan="6" className="text-center py-4">No hay clientes encontrados.</td>
                        </tr>
                    ) : (
                        clientes.map(cliente => (
                            <tr
                                key={cliente._id}
                                // className={`border-b border-gray-200 hover:bg-gray-100 ${cliente.saldoPendiente > 0 ? 'bg-gray-50' : ''}`}
                                className={`border-b border-gray-200 hover:bg-gray-100 ${cliente.saldoPendiente.toLocaleString('es-CL') > 0 ? 'bg-gray-50' : ''}`}
                            >
                                <td className="py-3 px-6 text-left">{cliente.nombre}</td>
                                <td className="py-3 px-6 text-left">{cliente.rut}</td>
                                <td className="py-3 px-6 text-left text-lg font-bold">
                                    ${cliente.saldoPendiente.toLocaleString('es-CL')} ({cliente.cantidadPendiente} )
                                </td>
                                <td className="py-3 px-6 text-left text-lg font-bold">
                                    ${cliente.totalAbonado.toLocaleString('es-CL') !== undefined ? cliente.totalAbonado.toLocaleString('es-CL') : 0}
                                </td>
                                <td className="py-3 px-6 text-center">
                                    <div className="flex space-x-2">
                                        <button onClick={() => handleViewDetails(cliente.rut)}
                                            className="bg-yellow-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-yellow-600">
                                            <FontAwesomeIcon icon={faEye} />
                                        </button>
                                        <button
                                            onClick={() => handleEditClick(cliente)}
                                            className="bg-blue-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-blue-600"
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                        <button
                                            onClick={() => handleDelete(cliente._id)}
                                            className="bg-red-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-red-600"
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))
                        )}
                    </tbody>
                </table>

                {/* Controles de Paginación */}
            {renderPagination()}

           

            {/* Modal de edición */}
            {selectedCliente && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded shadow-lg  w-full max-w-xl">
                        <h3 className="text-xl font-bold mb-4">Editar Cliente</h3>
                        {/* Formulario de edición */}
                        <form onSubmit={handleUpdateCliente}>
                            <label className="block mb-2">
                                Nombre:
                                <input
                                    type="text"
                                    value={selectedCliente.nombre}
                                    onChange={(e) => setSelectedCliente({ ...selectedCliente, nombre: e.target.value })}
                                    className="border border-gray-300 p-2 rounded w-full"
                                />
                            </label>
                            <label className="block mb-2">
                                RUT:
                                <input
                                    type="text"
                                    value={selectedCliente.rut}
                                    onChange={(e) => setSelectedCliente({ ...selectedCliente, rut: e.target.value })}
                                    className="border border-gray-300 p-2 rounded w-full"
                                />
                            </label>
                            <label className="block mb-2">
                                DIRECCION:
                                <input
                                    type="text"
                                    value={selectedCliente.direccion}
                                    onChange={(e) => setSelectedCliente({ ...selectedCliente, direccion: e.target.value })}
                                    className="border border-gray-300 p-2 rounded w-full"
                                />
                            </label>
                            <label className="block mb-2">
                                EMAIL:
                                <input
                                    type="text"
                                    value={selectedCliente.email}
                                    onChange={(e) => setSelectedCliente({ ...selectedCliente, email: e.target.value })}
                                    className="border border-gray-300 p-2 rounded w-full"
                                />
                            </label>
                            <div className="flex justify-between mt-4">
                                <button
                                    type="button"
                                    onClick={() => setSelectedCliente(null)}
                                    className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                                >
                                    Cancelar
                                </button>
                                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

        </div>

    );
};

export default ClienteManager;
