// src/api.js
import axios from 'axios';

const token = localStorage.getItem('token');

const API_URL = 'https://sistemaoh-backend.onrender.com/api';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
  withCredentials: true // Añade esta línea para enviar credenciales
});

// Agregar interceptores si es necesario
// api.interceptors.request.use(...);
// api.interceptors.response.use(...);

export default api;