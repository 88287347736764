import React, { useState, useEffect } from 'react';
import api from '../api';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

const ClienteForm = ({ cliente, onClienteSaved }) => {
  const [nombre, setNombre] = useState(cliente ? cliente.nombre : '');
  const [rut, setRut] = useState(cliente ? cliente.rut : '');
  const [direccion, setDireccion] = useState(cliente ? cliente.direccion : '');
  const [email, setEmail] = useState(cliente ? cliente.email : '');
  const [saldoPendiente, setSaldoPendiente] = useState(cliente ? cliente.saldoPendiente : 0);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchCliente(id);
    }
  }, [id]);

  const fetchCliente = async (id) => {
    const token = localStorage.getItem('token');
    try {
      const response = await api.get(`/clientes/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const cliente = response.data;
      setNombre(cliente.nombre);
      setRut(cliente.rut);
      setDireccion(cliente.direccion);
      setEmail(cliente.email);
      setSaldoPendiente(cliente.saldoPendiente);
    } catch (error) {
      console.error('Error al cargar cliente:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const clienteData = {
      nombre,
      rut,
      direccion,
      email
    };

    try {
      if (id) {
        await api.put(`/clientes/${id}`, clienteData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        Swal.fire('Actualizado', 'Cliente actualizado correctamente', 'success');
      } else {
        await api.post('/clientes', clienteData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        Swal.fire('Creado', 'Cliente creado correctamente', 'success');
      }
      navigate('/clientes');
    } catch (error) {
      console.error('Error al guardar el cliente:', error);
      Swal.fire('Error', 'No se pudo guardar el cliente. Intente de nuevo más tarde.', 'error');
    }
  };

  const resetForm = () => {
    setNombre('');
    setRut('');
    setDireccion('');
    setEmail('');
    
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="bg-white shadow-xl rounded-3xl overflow-hidden w-full max-w-md">
        <div className="p-6">
          <h2 className="text-2xl font-semibold mb-4">{id ? 'Editar Cliente' : 'Crear Cliente'}</h2>
          {message && <div className="alert alert-info">{message}</div>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Nombre</label>
              <input
                type="text"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                required
                className="mt-1 p-2 border border-gray-300 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">RUT</label>
              <input
                type="text"
                value={rut}
                onChange={(e) => setRut(e.target.value)}
                required
                className="mt-1 p-2 border border-gray-300 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Dirección</label>
              <input
                type="text"
                value={direccion}
                onChange={(e) => setDireccion(e.target.value)}
                required
                className="mt-1 p-2 border border-gray-300 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-1 p-2 border border-gray-300 rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Saldo Pendiente</label>
              <input
                type="number"
                value={saldoPendiente}
                onChange={(e) => setSaldoPendiente(e.target.value)}
                required
                className="mt-1 p-2 border border-gray-300 rounded w-full"
              />
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600"
              >
                {id ? 'Actualizar' : 'Crear'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ClienteForm;