import React, { useEffect, useState } from 'react';
import api from '../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faSave, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';


const UsuarioList = ({ currentUser }) => {
    const [usuarios, setUsuarios] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedUsuario, setSelectedUsuario] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false)
    const [formData, setFormData] = useState({
        nombre: '',
        email: '',
        role:'',
        password: '',
    });

    const token = localStorage.getItem('token'); // Obtener el token del localStorage

    useEffect(() => {
        const fetchUsuarios = async () => {                        
            try {
                const response = await api.get('/usuarios', {
                    headers: {
                        Authorization: `Bearer ${token}` // Incluir el token en los headers
                    }
                });
                setUsuarios(response.data);
                setLoading(false);
            } catch (err) {
                console.error(err);
                setError('Error al obtener la lista de usuarios.');
                setLoading(false);
            }
        };
        fetchUsuarios();
    }, [token]);

    
    
    // Manejar la eliminación de un usuario
    const handleDelete = (id) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await api.delete(`/usuarios/${id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setUsuarios(usuarios.filter(usuario => usuario._id !== id));
                    Swal.fire(
                        'Eliminado!',
                        'El usuario ha sido eliminado.',
                        'success'
                    );
                } catch (err) {
                    console.error(err);
                    Swal.fire(
                        'Error!',
                        'Hubo un problema al eliminar el usuario.',
                        'error'
                    );
                }
            }
        });
    }


    // Abrir el modal de edición
    const handleEdit = (usuario) => {
        setSelectedUsuario(usuario);
        setFormData({
            nombre: usuario.nombre,
            email: usuario.email,
            role: usuario.role,
        });
        setShowEditModal(true);
    };

    // Manejar cambios en el formulario de edición
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    
    

     // Guardar los cambios del usuario editado
    const handleSave = async (e) => {
        e.preventDefault();
        try {
            const updatedData = {
                nombre: formData.nombre,
                email: formData.email,
                role: formData.role,
            };
            const response = await api.put(`/usuarios/${selectedUsuario._id}`, updatedData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUsuarios(usuarios.map(usuario => (usuario._id === selectedUsuario._id ? response.data : usuario)));
            setShowEditModal(false);
            setSelectedUsuario(null);
            Swal.fire(
                'Actualizado!',
                'Los datos del usuario han sido actualizados.',
                'success'
            );
        } catch (err) {
            console.error(err);
            Swal.fire(
                'Error!',
                'Hubo un problema al actualizar el usuario.',
                'error'
            );
        }
    }



// Abrir el modal de agregar nuevo usuario
const handleAdd = () => {
    setFormData({
        nombre: '',
        email: '',
        role: '',
        password: '',
    });
    setShowAddModal(true);
};

// Guardar un nuevo usuario
const handleAddSave = async (e) => {
    e.preventDefault();
    try {
        // Asegúrate de que todos los campos requeridos están presentes
        const newUser = {
            nombre: formData.nombre,
            email: formData.email,
            role: formData.role,
            password: formData.password,
        };
        const response = await api.post('/usuarios', newUser, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        setUsuarios([...usuarios, response.data]);
        setShowAddModal(false);
        Swal.fire(
            'Creado!',
            'El nuevo usuario ha sido creado.',
            'success'
        );
    } catch (err) {
        console.error(err);
        // Extraer error del backend
        const message = err.response?.data?.error || 'Hubo un problema al crear el usuario.';
        Swal.fire(
            'Error!',
            message,
            'error'
        );
    }
}

// Cerrar el modal de edición
const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedUsuario(null);
};

// Cerrar el modal de agregar
const handleCloseAddModal = () => {
    setShowAddModal(false);
};

if (loading) {
    return <div className="flex justify-center items-center h-screen">Cargando...</div>;
}

if (error) {
    return <div className="text-red-500 text-center mt-4">{error}</div>;
}

return (
    <div className="p-6 bg-gray-100 min-h-screen">
        <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold">Lista de Usuarios</h1>
            <button
                onClick={handleAdd}
                className="bg-green-500 text-white rounded-full w-12 h-12 flex items-center justify-center hover:bg-green-600"
            >
                <FontAwesomeIcon icon={faPlus} /> 
                
            </button>
        </div>

        {/* Tabla de usuarios */}
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                    <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                        <th className="py-3 px-6 text-left">Nombre</th>
                        <th className="py-3 px-6 text-left">Email</th>
                        <th className="py-3 px-6 text-left">Rol</th>
                        <th className="py-3 px-6 text-center">Acciones</th>
                    </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                    {usuarios.map(usuario => (
                        <tr
                            key={usuario._id}
                            className="border-b border-gray-200 hover:bg-gray-100"
                        >
                            <td className="py-3 px-6 text-left">
                                {usuario.nombre}
                            </td>
                            <td className="py-3 px-6 text-left">
                                {usuario.email}
                            </td>
                            <td className="py-3 px-6 text-left">
                                {usuario.role}
                            </td>
                            <td className="py-3 px-6 text-center">
                                <div className="flex items-center justify-center space-x-4">
                                    <button
                                        onClick={() => handleEdit(usuario)}
                                        className="bg-blue-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-blue-600"
                                    >
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                    <button
                                        onClick={() => handleDelete(usuario._id)}
                                        className="bg-red-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-red-600"
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

        {/* Modal de edición */}
        {showEditModal && selectedUsuario && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
                    <h3 className="text-xl font-bold mb-4">Editar Usuario</h3>
                    <form onSubmit={handleSave}>
                        <div className="mb-4">
                            <label className="block text-gray-700">Nombre:</label>
                            <input
                                type="text"
                                name="nombre"
                                value={formData.nombre}
                                onChange={handleChange}
                                required
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Email:</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Rol:</label>
                            <select
                                name="role"
                                value={formData.role} 
                                onChange={handleChange}
                                required
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                            >
                                <option value="">Seleccionar Rol</option>
                                <option value="ADMIN">ADMIN</option>
                                <option value="FACTURACION">FACTURACION</option>
                                <option value="USER">USER</option>
                            </select>
                        </div>
                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                onClick={handleCloseEditModal}
                                className="bg-red-500 text-white rounded-full w-11 h-11 flex items-center justify-center hover:bg-red-600"
                            >
                                <FontAwesomeIcon icon={faTimes} />
                                
                            </button>
                            <button
                                type="submit"
                                className="bg-green-500 text-white rounded-full w-11 h-11 flex items-center justify-center hover:bg-green-600"
                            >
                                <FontAwesomeIcon icon={faSave} />
                               
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )}

        {/* Modal de agregar nuevo usuario */}
        {showAddModal && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
                    <h3 className="text-xl font-bold mb-4">Agregar Nuevo Usuario</h3>
                    <form onSubmit={handleAddSave}>
                        <div className="mb-4">
                            <label className="block text-gray-700">Nombre:</label>
                            <input
                                type="text"
                                name="nombre"
                                value={formData.nombre}
                                onChange={handleChange}
                                required
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Email:</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Contraseña:</label>
                            <input
                                type="password"
                                name="password"
                                value={formData.password || ''}
                                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                required
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                                placeholder="Ingrese una contraseña"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Rol:</label>
                            <select
                                name="role"
                                value={formData.role} 
                                onChange={handleChange}
                                required
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                            >
                                <option value="">Seleccionar Rol</option>
                                <option value="ADMIN">ADMIN</option>
                                <option value="FACTURACION">FACTURACION</option>
                                <option value="USER">USER</option>
                            </select>
                        </div>
                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                onClick={handleCloseAddModal}
                                className="bg-red-500 text-white rounded-full w-11 h-11 flex items-center justify-center hover:bg-red-600"
                            >
                                <FontAwesomeIcon icon={faTimes} />
                                
                            </button>
                            <button
                                type="submit"
                                className="bg-green-500 text-white rounded-full w-11 h-11 flex items-center justify-center hover:bg-green-600"
                            >
                                <FontAwesomeIcon icon={faSave} />
                                
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )}
    </div>
)
};

export default UsuarioList;
