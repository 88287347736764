// src/components/CrearFactura.jsx

import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import FacturaForm from './FacturaForm';
import Swal from 'sweetalert2';
import api from '../api';

const CrearFactura = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  // Manejar el envío del formulario para crear una nueva factura
  const handleSubmit = async (formData) => {
    const token = localStorage.getItem('token');
    try {
      await api.post(
        '/facturas',
        {
          numero: formData.numero,
          clienteRut: formData.clienteSeleccionado.value,
          fechaEmision: formData.fechaEmision,
          fechaPago: formData.fechaPago || null,
          estado: formData.estado,
          monto: formData.monto,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      Swal.fire('Éxito', 'Factura creada correctamente', 'success');
      // Navegar de vuelta a FacturaList con los parámetros de búsqueda
      navigate(`/facturas${location.state?.search}`);
    } catch (error) {
      console.error('Error al crear la factura:', error);
      Swal.fire('Error', 'No se pudo crear la factura', 'error');
    }
  };

  // Manejar la acción de "Volver" al crear una factura
  const handleVolver = () => {
    if (state && state.from === 'DetalleCliente' && state.selectedRut) {
      navigate(`/clientes/detalle/${state.selectedRut}`);
    } else {
      navigate(`/facturas${location.state?.search || ''}`);
    }
  };

  return (
    <div>
      <FacturaForm
        initialData={{}} // Sin datos iniciales para crear
        onSubmit={handleSubmit}
        onCancel={handleVolver}
      />
    </div>
  );
};

export default CrearFactura;