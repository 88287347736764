// src/components/UsuarioForm.jsx
import React, { useEffect, useState, useContext } from 'react';
import api from '../api';
import Swal from 'sweetalert2';
import { UserContext } from '../context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faUserEdit } from '@fortawesome/free-solid-svg-icons';

const UsuarioForm = () => {
    const { currentUser, setCurrentUser } = useContext(UserContext);
    const { id, nombre: currentNombre, email: currentEmail, role, avatar } = currentUser || {};

    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [availableAvatars, setAvailableAvatars] = useState([]);
    const [selectedAvatar, setSelectedAvatar] = useState(avatar || 'hombre1.png');


     // Obtener la lista de avatares predefinidos
     const fetchAvatars = () => {
        // Supongamos que tienes una lista estática de avatares
        const avatars = [
            'mujer1.png',
            'mujer2.png',
            'mujer3.png',
            'mujer4.png',
            'hombre1.png',
            'hombre2.png',
            'hombre3.png',
            'hombre4.png'

            // Agrega más avatares según tus necesidades
        ];
        setAvailableAvatars(avatars);
    };

    useEffect(() => {
        fetchAvatars();
    }, [])


    useEffect(() => {
        if (showModal && currentUser) {
            setNombre(currentNombre || '');
            setEmail(currentEmail || '');
            setPassword('');
            setSelectedAvatar(avatar || 'hombre1.png');
        } else if (showModal && !currentUser?.id) {
            console.error("currentUser es nulo o no tiene 'id' al abrir el modal");
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No se pudo obtener la información del usuario.',
            });
            setShowModal(false);
        }
    }, [showModal, currentUser, currentNombre, currentEmail, id, avatar]);

    // Función para refrescar el estado del usuario en el contexto
    const refreshUser = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await api.get('/usuarios/me', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setCurrentUser({
                ...response.data,
                id: response.data.id || response.data._id // Asegurar que 'id' esté presente
            });
        } catch (error) {
            console.error('Error al refrescar el usuario:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No se pudo refrescar el estado del usuario.',
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        const usuarioId = id;

        if (!usuarioId) {
            console.error("No se pudo obtener el ID del usuario");
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No se pudo obtener el ID del usuario.',
            });
            return;
        }

        const usuarioData = {
            nombre,
            email,
            avatar: selectedAvatar, // Enviamos el avatar seleccionado
            ...(password ? { password } : {}),
        };

        try {
            const response = await api.put(`/usuarios/${usuarioId}`, usuarioData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setShowModal(false);
            await refreshUser();

            Swal.fire({
                icon: 'success',
                title: '¡Usuario actualizado!',
                text: 'Los datos del usuario se han guardado correctamente.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK',
            });

        } catch (error) {
            const errorMessage = error.response?.data?.message || 'No se pudo actualizar el usuario. Intente de nuevo más tarde.';
            console.error('Error al guardar el usuario:', errorMessage);

            Swal.fire({
                icon: 'error',
                title: 'Error al guardar el usuario',
                text: 'No se pudo actualizar el usuario. Intente de nuevo más tarde.',
                confirmButtonColor: '#d33',
                confirmButtonText: 'Cerrar',
            });
        }
    };

    return (
        <div className="p-8 bg-gradient-to-r from-blue-200 to-purple-300 min-h-screen flex flex-col items-center justify-center space-y-8">
            {/* Vista del Perfil - Tarjeta Moderna */}
            <div className="bg-white shadow-xl rounded-3xl overflow-hidden w-full max-w-md">
                <div className="p-6 flex flex-col items-center">
                    {/* Avatar con etiqueta "Mi Avatar" */}
                    <div className="flex flex-col items-center mb-4">
                        
                        <div className="w-32 h-32 rounded-full overflow-hidden border-4 border-blue-500">
                            {avatar ? (
                                <img src={`/assets/avatars/${avatar}`} alt="Mi Avatar" className="w-full h-full object-cover" />
                            ) : (
                                <span className="text-4xl text-gray-500">👤</span>
                            )}
                        </div>
                    </div>
                    {/* Información del Usuario */}
                    <div className="text-center">
                        <div className="flex items-center justify-center mb-2">
                            <h2 className="text-2xl font-bold text-gray-800 mr-2">{nombre || currentNombre}</h2>
                            {/* Botón para Editar Usuario (Solo Icono) */}
                            <button
                                onClick={() => setShowModal(true)}
                                className="text-gray-600 hover:text-gray-800 transition-colors"
                                aria-label="Editar Usuario"
                            >
                                <FontAwesomeIcon icon={faUserEdit} size="lg" />
                            </button>
                        </div>
                        <p className="text-gray-600 mb-1"><strong>Email:</strong> {email || currentEmail}</p>
                        <p className="text-gray-600 mb-4"><strong>Rol:</strong> {role}</p>
                    </div>
                </div>
            </div>

            {/* Modal de Edición */}
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white backdrop-filter backdrop-blur-lg p-6 md:p-8 rounded-3xl shadow-2xl w-11/12 md:w-3/4 lg:w-1/2 relative overflow-y-auto max-h-screen">
                        {/* Botón de Cerrar */}
                        <button
                            type="button"
                            onClick={() => setShowModal(false)}
                            className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 transition-colors"
                        >
                            <FontAwesomeIcon icon={faTimes} size="lg" />
                        </button>
                        {/* Encabezado del Modal */}
                        <h2 className="text-2xl md:text-3xl font-semibold mb-6 text-gray-800 text-center">Editar Usuario</h2>
                        {/* Formulario de Edición */}
                        <form onSubmit={handleSubmit} className="space-y-6">
                            {/* Sección de Avatar y Selección */}
                            <div className="flex flex-col md:flex-row items-center md:space-x-6 space-y-6 md:space-y-0">
                                {/* Avatar Seleccionado con etiqueta */}
                                <div className="flex flex-col items-center">
                                    
                                    <div className="w-32 h-32 rounded-full overflow-hidden border-4 border-blue-500">
                                        {selectedAvatar ? (
                                            <img src={`/assets/avatars/${selectedAvatar}`} alt="Mi Avatar" className="w-full h-full object-cover" />
                                        ) : (
                                            <span className="text-4xl text-gray-500">👤</span>
                                        )}
                                    </div>
                                </div>
                                {/* Selección de Avatares en Recuadro */}
                                <div className="w-full border-2 border-gray-300 p-6 rounded-xl bg-gray-50">
                                    <label className="block text-xl font-medium text-gray-700 mb-4 text-center">Selecciona un Avatar:</label>
                                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 justify-items-center">
                                        {availableAvatars.map((av, index) => (
                                            <button
                                                type="button"
                                                key={index}
                                                onClick={() => setSelectedAvatar(av)}
                                                className={`w-20 h-20 rounded-full overflow-hidden border-4 ${
                                                    selectedAvatar === av ? 'border-blue-500' : 'border-transparent'
                                                } hover:border-blue-300 transition-colors`}
                                                aria-label={`Seleccionar Avatar ${index + 1}`}
                                            >
                                                <img src={`/assets/avatars/${av}`} alt={`Avatar ${index + 1}`} className="w-full h-full object-cover" />
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* Campos del Formulario */}
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                {/* Nombre */}
                                <div>
                                    <label htmlFor="nombre" className="block text-sm font-medium text-gray-700 mb-1">
                                        Nombre:
                                    </label>
                                    <input
                                        type="text"
                                        id="nombre"
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                        required
                                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 bg-white bg-opacity-90"
                                        placeholder="Ingrese el nombre"
                                    />
                                </div>
                                {/* Email */}
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                                        Email:
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 bg-white bg-opacity-90"
                                        placeholder="Ingrese el email"
                                    />
                                </div>
                                {/* Contraseña */}
                                <div className="md:col-span-2">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                                        Contraseña{' '}
                                        <span className="text-xs font-normal text-gray-500">
                                            (dejar en blanco si no desea cambiar)
                                        </span>
                                        :
                                    </label>
                                    <input
                                        type="password"
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Nueva contraseña"
                                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 bg-white bg-opacity-90"
                                    />
                                </div>
                            </div>
                            {/* Botones de Acción */}
                            <div className="flex justify-center space-x-4">
                                <button
                                    type="submit"
                                    className="flex items-center bg-blue-600 text-white rounded-full px-6 py-3 hover:bg-blue-700 transition duration-200 shadow-md"
                                >
                                    <FontAwesomeIcon icon={faSave} className="mr-2" />
                                    Guardar Cambios
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setShowModal(false)}
                                    className="flex items-center bg-gray-300 text-gray-700 rounded-full px-6 py-3 hover:bg-gray-400 transition duration-200"
                                >
                                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                                    Cancelar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UsuarioForm;