import React, { useState } from 'react';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../OH.png';

const Login = ({onLogin}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate(); // Asegúrate de que esta línea esté aquí

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await api.post('/usuarios/login', { email, password });

        
        // Verificar la existencia del token
        if (response.data.token) {
            localStorage.setItem('token', response.data.token);
            // console.log('Token guardado:', response.data.token);
            onLogin({ token: response.data.token, usuario: response.data.usuario })  // Llama a la función onLogin con el usuario
            navigate('/dashboard'); // Navega a la página de dashboard
        } else {
           // console.log('No se recibió un token. Token:', response.data.token);
            setErrorMessage('No se recibió un token. Verifica tus credenciales.');
        }
    } catch (err) {
        // Imprimir error para más información
        console.error('Error al iniciar sesión:', err);
        setErrorMessage('Error al iniciar sesión: ' + (err.response?.data?.message || err.message));
    }
}



const backgroundStyle = {
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
};

  return (
    <section className="h-screen flex items-center justify-center bg-gradient-to-br from-indigo-500 to-blue-600" style={backgroundStyle}>
      <div className="container mx-auto px-6 py-12 h-full flex items-center justify-center">
        <div className="w-full max-w-md bg-white rounded-2xl shadow-lg overflow-hidden transform transition-all duration-500 hover:scale-105">
          <div className="px-10 py-12 bg-gradient-to-r from-white to-gray-50">
            
            <h2 className="text-center text-3xl font-extrabold mb-8 text-indigo-500 tracking-tight">Bienvenido</h2>
            {errorMessage && (
              <div className="bg-red-100 text-red-600 p-3 rounded-md text-center mb-6">
                {errorMessage}
              </div>
            )}
            
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Email input */}
              <div className="relative">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Correo electrónico"
                  className="peer placeholder-transparent h-14 w-full rounded-md border border-gray-300 px-4 text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-400"
                  required
                />
                <label className="absolute left-4 -top-2.5 bg-white px-1 text-indigo-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 peer-focus:-top-2.5 peer-focus:text-indigo-600">
                  Correo electrónico
                </label>
              </div>
  
              {/* Password input */}
              <div className="relative">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Contraseña"
                  className="peer placeholder-transparent h-14 w-full rounded-md border border-gray-300 px-4 text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-400"
                  required
                />
                <label className="absolute left-4 -top-2.5 bg-white px-1 text-indigo-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 peer-focus:-top-2.5 peer-focus:text-indigo-600">
                  Contraseña
                </label>
              </div>
  
              {/* Sign in button */}
              <button
                type="submit"
                className="w-full rounded-md bg-gradient-to-r from-indigo-500 to-blue-600 px-8 py-4 text-lg font-semibold text-white shadow-md hover:from-indigo-600 hover:to-purple-700 transition-all ease-in-out duration-150 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-indigo-400"
              >
                Ingresar
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login;
