// src/components/ClientDetails.jsx

import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import api from '../api';
import { UserContext } from '../context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEdit, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

const DetalleCliente = () => {
    const { currentUser } = useContext(UserContext);
    const { rut } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [clientes, setClientes] = useState([]);
    const [selectedRut, setSelectedRut] = useState(rut || '');
    const [searchTerm, setSearchTerm] = useState('');
    const [facturas, setFacturas] = useState([]);
    const [honorarios, setHonorarios] = useState([]);
    const [resumenFacturas, setResumenFacturas] = useState({
        cantidadFacturas: 0,
        totalMonto: 0,
        totalPagado: 0,
        totalPendiente: 0,
    });
    const [resumenHonorarios, setResumenHonorarios] = useState({
        cantidadHonorarios: 0,
        totalMonto: 0,
        totalPagado: 0,
        totalPendiente: 0,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedFacturas, setSelectedFacturas] = useState([]);
    const [selectedHonorarios, setSelectedHonorarios] = useState([]);

    // Fetch all clients for the select dropdown
    useEffect(() => {
        const fetchClientes = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await api.get('/clientes', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                //console.log('Clientes:', response.data); // Añadido para depuración
                setClientes(response.data);
            } catch (err) {
                console.error('Error al obtener clientes:', err);
                setError('Error al obtener clientes.');
            }
        };

        fetchClientes();
    }, []);

    // Fetch facturas and honorarios when a client is selected
    useEffect(() => {
        if (selectedRut) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [selectedRut]);

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const token = localStorage.getItem('token');

            // Fetch Facturas
            const responseFacturas = await api.get(
                `/facturas/rut/${selectedRut}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            //console.log('Facturas:', responseFacturas.data); // Añadido para depuración
            //setFacturas(Array.isArray(responseFacturas.data) ? responseFacturas.data : []);

            // Ordenar facturas por número
            const facturasOrdenadas = Array.isArray(responseFacturas.data)
                ? responseFacturas.data.sort((a, b) => a.numero - b.numero)
                : [];
            setFacturas(facturasOrdenadas);

            // Fetch Honorarios
            const responseHonorarios = await api.get(
                `/honorarios/rut/${selectedRut}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            //console.log('Honorarios:', responseHonorarios.data); // Añadido para depuración
            const honorariosOrdenados = Array.isArray(responseHonorarios.data)
                ? responseHonorarios.data.sort((a, b) => a.descripcion.localeCompare(b.descripcion))
                : [];
            setHonorarios(honorariosOrdenados);

            // Calcular Resumen Facturas
            const totalMonto = Array.isArray(responseFacturas.data)
                ? responseFacturas.data.reduce((acc, factura) => acc + factura.monto, 0)
                : 0;
            const totalPagado = Array.isArray(responseFacturas.data)
                ? responseFacturas.data.reduce((acc, factura) => acc + (factura.total_abonado || 0), 0)
                : 0;
            const totalPendiente = totalMonto - totalPagado;
            setResumenFacturas({
                cantidadFacturas: Array.isArray(responseFacturas.data) ? responseFacturas.data.length : 0,
                totalMonto,
                totalPagado,
                totalPendiente,
            });

            // Calcular Resumen Honorarios
            const totalMontoHonorarios = Array.isArray(responseHonorarios.data)
                ? responseHonorarios.data.reduce((acc, honorario) => acc + honorario.monto, 0)
                : 0;
            const totalPagadoHonorarios = Array.isArray(responseHonorarios.data)
                ? responseHonorarios.data.reduce((acc, honorario) => acc + (honorario.total_abonado || 0), 0)
                : 0;
            const totalPendienteHonorarios = totalMontoHonorarios - totalPagadoHonorarios;
            setResumenHonorarios({
                cantidadHonorarios: Array.isArray(responseHonorarios.data) ? responseHonorarios.data.length : 0,
                totalMonto: totalMontoHonorarios,
                totalPagado: totalPagadoHonorarios,
                totalPendiente: totalPendienteHonorarios,
            });
        } catch (err) {
            console.error('Error al obtener datos:', err);
            // Verifica si el error está relacionado con la falta de documentos
            // Esto depende de cómo el backend maneja estos casos
            // Por ejemplo, si retorna un 404 cuando no hay documentos, podrías manejarlo aquí
            if (err.response && err.response.status === 404) {
                // No se encontraron documentos para el cliente
                setFacturas([]);
                setHonorarios([]);
                setResumenFacturas({
                    cantidadFacturas: 0,
                    totalMonto: 0,
                    totalPagado: 0,
                    totalPendiente: 0,
                });
                setResumenHonorarios({
                    cantidadHonorarios: 0,
                    totalMonto: 0,
                    totalPagado: 0,
                    totalPendiente: 0,
                });
            } else {
                // Otro tipo de error
                setError('Error al obtener datos del cliente.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSelectChange = (e) => {
        setSelectedRut(e.target.value);
    };

    const filteredClientes = Array.isArray(clientes)
        ? clientes.filter((cliente) => {
            const term = searchTerm.toLowerCase();
            return (
                cliente.nombre.toLowerCase().includes(term) ||
                cliente.rut.toLowerCase().includes(term)
            );
        })
        : [];


    const handleAbonarFactura = (factura) => {
        //console.log('Factura seleccionada:', factura)
        // Redirigir al formulario de abonos y pasar la factura seleccionada como estado
        navigate(`/abonos${location.search}`, { state: { factura } });
    }


    const filteredFacturas = facturas.filter(factura => {
        //console.log('Facturas:', facturas); // Depuración
        const clienteNombre = clientes[factura.clienteRut] || '';

        return (
            factura.numero.toString().includes(searchTerm) ||
            clienteNombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
            factura.clienteRut.includes(searchTerm)
        );
    })


    const handleEditFactura = (factura) => {
        navigate(`/facturas/editar/${factura._id}`, {
            state: {
                from: 'DetalleCliente',
                rutCliente: selectedRut, // Pasa el RUT del cliente
            },
        });
    };


    // Función para seleccionar/deseleccionar una factura
    const handleSelectFactura = (id) => {
        if (selectedFacturas.includes(id)) {
            setSelectedFacturas(selectedFacturas.filter((facturaId) => facturaId !== id));
        } else {
            setSelectedFacturas([...selectedFacturas, id]);
        }
    };

    // Función para seleccionar/deseleccionar todas las facturas
    const handleSelectAll = () => {
        if (selectedFacturas.length === facturas.length) {
            setSelectedFacturas([]);
        } else {
            setSelectedFacturas(facturas.map((factura) => factura._id));
        }
    };

    // Variable para controlar el estado del checkbox "Seleccionar Todo"
    const isAllSelected = selectedFacturas.length === facturas.length && facturas.length > 0;


    // Seleccionar/Deseleccionar un honorario
    const handleSelectHonorario = (id) => {
        setSelectedHonorarios((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((honorarioId) => honorarioId !== id)
                : [...prevSelected, id]
        );
    };

    // Seleccionar/Deseleccionar todos los honorarios
    const handleSelectAllHonorarios = () => {
        if (isAllHonorariosSelected) {
            setSelectedHonorarios([]);
        } else {
            const allHonorarioIds = honorarios.map((honorario) => honorario._id);
            setSelectedHonorarios(allHonorarioIds);
        }
    };

    // Verificar si todos los honorarios están seleccionados
    const isAllHonorariosSelected = selectedHonorarios.length === honorarios.length && honorarios.length > 0;


    const handlePagoMasivoHonorarios = async () => {
        if (selectedHonorarios.length === 0) {
            Swal.fire('No hay honorarios seleccionados', 'Por favor, selecciona al menos un honorario para pagar.', 'warning');
            return;
        }

        const confirm = await Swal.fire({
            title: '¿Estás seguro?',
            text: `Vas a pagar ${selectedHonorarios.length} honorario(s).`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí, pagar',
            cancelButtonText: 'Cancelar',
        });

        if (confirm.isConfirmed) {
            try {
                setLoading(true);
                const token = localStorage.getItem('token');

                const response = await api.put(
                    '/honorarios/pagar-masivo',
                    { honorarioIds: selectedHonorarios },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                Swal.fire('Éxito', response.data.message, 'success');
                fetchData(); // Función para refrescar los datos
                setSelectedHonorarios([]);
            } catch (error) {
                console.error('Error al pagar masivamente honorarios:', error);
                Swal.fire('Error', 'Hubo un error al pagar los honorarios seleccionados.', 'error');
            } finally {
                setLoading(false);
            }
        }
    };


    const handleDeleteSelected = async () => {
        if (selectedFacturas.length === 0) return;

        const confirm = await Swal.fire({
            title: '¿Estás seguro?',
            text: 'Se eliminarán las facturas seleccionadas.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, eliminar',
        });

        if (confirm.isConfirmed) {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                await api.delete('/facturas/bulk-delete', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    data: { ids: selectedFacturas },
                });
                Swal.fire('Eliminado', 'Facturas eliminadas correctamente.', 'success');
                setSelectedFacturas([]);
                // Refrescar la lista de facturas
                fetchData(selectedRut);
            } catch (error) {
                console.error('Error al eliminar facturas:', error);
                Swal.fire('Error', 'No se pudieron eliminar las facturas.', 'error');
            } finally {
                setLoading(false);
            }
        }
    };


    // Función para manejar la eliminación masiva de honorarios
    const handleEliminarHonorariosMasivo = async () => {
        if (selectedHonorarios.length === 0) {
            Swal.fire('No hay honorarios seleccionados', 'Por favor, selecciona al menos un honorario para eliminar.', 'warning');
            return;
        }

        const confirm = await Swal.fire({
            title: '¿Estás seguro?',
            text: `Vas a eliminar ${selectedHonorarios.length} honorario(s). Esta acción es irreversible.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        });

        if (confirm.isConfirmed) {
            try {
                setLoading(true);
                const token = localStorage.getItem('token');

                const response = await api.delete('/honorarios/eliminar-masivo', {
                    data: { honorarioIds: selectedHonorarios },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                Swal.fire('Eliminado', response.data.message, 'success');
                fetchData(); // Función para refrescar los datos
                setSelectedHonorarios([]);
            } catch (error) {
                console.error('Error al eliminar masivamente honorarios:', error);
                Swal.fire('Error', 'Hubo un error al eliminar los honorarios seleccionados.', 'error');
            } finally {
                setLoading(false);
            }
        }
    };




    // Función para manejar el pago masivo de facturas
    const handlePagoMasivo = async () => {
        if (selectedFacturas.length === 0) {
            Swal.fire('No hay facturas seleccionadas', 'Por favor, selecciona al menos una factura para pagar.', 'warning');
            return;
        }

        const confirm = await Swal.fire({
            title: '¿Estás seguro?',
            text: `Vas a pagar ${selectedFacturas.length} factura(s).`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí, pagar',
            cancelButtonText: 'Cancelar',
        });

        if (confirm.isConfirmed) {
            try {
                setLoading(true);
                const token = localStorage.getItem('token');

                const response = await api.put(
                    '/facturas/pagar-masivo',
                    { facturaIds: selectedFacturas },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                Swal.fire('Éxito', response.data.message, 'success');
                fetchData(); // Función para refrescar los datos
                setSelectedFacturas([]);
            } catch (error) {
                console.error('Error al pagar masivamente facturas:', error);
                Swal.fire('Error', 'Hubo un error al pagar las facturas seleccionadas.', 'error');
            } finally {
                setLoading(false);
            }
        }
    };




    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <h1 className="text-3xl font-semibold mb-6 text-center">Detalles del Cliente</h1>
            {/* Selección de Cliente */}
            <div className="mb-6">
                <select
                    value={selectedRut}
                    onChange={handleSelectChange}
                    className="border border-gray-300 p-3 rounded w-full text-lg focus:ring-2 focus:ring-blue-500 focus:outline-none shadow-md"
                >
                    <option value="">-- Seleccionar Cliente --</option>
                    {filteredClientes.map((cliente) => (
                        <option key={cliente._id} value={cliente.rut}>
                            {cliente.nombre} ({cliente.rut})
                        </option>
                    ))}
                </select>
            </div>

            {/* Select Cliente con Buscador */}

            {/* <label className="block text-gray-700 mb-2 text-lg font-medium">Seleccionar Cliente:</label> */}
            <div className="mb-6">
                <input
                    type="text"
                    placeholder="Buscar por nombre o RUT"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="border border-gray-300 p-3 pl-12 rounded-full w-full focus:ring-2 focus:ring-blue-500 focus:outline-none text-lg shadow-md"
                />
                <span className="absolute left-3 top-2">
                    <FontAwesomeIcon icon={faSearch} className="text-gray-400 text-xl" />
                </span>
            </div>

            {/* Botones de Acción */}
            <div className="flex justify-end space-x-4 mb-6">
                {/* Botón Crear Factura */}
                <button
                    onClick={() =>
                        navigate('/facturas/nueva', {
                            state: {
                                from: 'DetalleCliente',
                                selectedRut
                            },
                        })
                    }
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200"
                >
                    Crear Factura
                </button>

                {/* Botón Eliminar Seleccionadas */}
                <button
                    onClick={handleDeleteSelected}
                    disabled={selectedFacturas.length === 0 || loading}
                    className={`bg-red-500 text-white px-4 py-2 rounded-lg ${selectedFacturas.length === 0 || loading
                        ? 'opacity-50 cursor-not-allowed'
                        : 'hover:bg-red-600'
                        }`}
                >
                    {loading ? 'Eliminando...' : 'Eliminar Seleccionadas'}
                </button>
                {/* Botón para Pago Masivo */}
                <button
                    onClick={handlePagoMasivo}
                    disabled={selectedFacturas.length === 0 || loading}
                    className={`mt-4 px-4 py-2 bg-green-500 text-white rounded ${selectedFacturas.length === 0 || loading
                        ? 'opacity-50 cursor-not-allowed'
                        : 'hover:bg-green-600'
                        }`}
                >
                    Pagar Seleccionados
                </button>
            </div>



            {/* Mostrar detalles si un cliente está seleccionado */}
            {selectedRut && (
                <>
                    {loading ? (
                        <div className="flex items-center justify-center h-40">
                            <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
                        </div>
                    ) : error ? (
                        <div className="text-red-500 text-center mb-4 text-xl">{error}</div>
                    ) : (
                        <>
                            {/* Verificar si no hay facturas y honorarios */}
                            {facturas.length === 0 && honorarios.length === 0 ? (
                                <div className="text-center text-gray-700 text-xl">
                                    Cliente sin documentos emitidos.
                                </div>
                            ) : (
                                <>
                                    {/* Resumen Facturas y Honorarios */}
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                                        {/* Resumen Facturas */}
                                        <div className="bg-white p-6 rounded-xl shadow-lg border-t-4 border-blue-500">
                                            <h2 className="text-2xl font-semibold mb-4 text-gray-800 text-center flex items-center justify-center">
                                                <span className="text-blue-500 mr-2">📄</span> Resumen Facturas
                                            </h2>
                                            <div className="space-y-2">
                                                <div className="flex justify-between">
                                                    <span className="text-gray-600">Cantidad de Facturas:</span>
                                                    <span className="font-medium text-gray-800">{resumenFacturas.cantidadFacturas}</span>
                                                </div>
                                                <div className="flex justify-between">
                                                    <span className="text-gray-600">Total Monto Facturado:</span>
                                                    <span className="font-medium text-gray-800">
                                                        ${resumenFacturas.totalMonto.toLocaleString('es-CL')}
                                                    </span>
                                                </div>
                                                <div className="flex justify-between">
                                                    <span className="text-gray-600">Total Abonado:</span>
                                                    <span className="font-medium text-gray-800">
                                                        ${resumenFacturas.totalPagado.toLocaleString('es-CL')}
                                                    </span>
                                                </div>
                                                <div className="flex justify-between">
                                                    <span className="text-gray-600">Total Pendiente:</span>
                                                    <span className="font-medium text-gray-800">
                                                        ${resumenFacturas.totalPendiente.toLocaleString('es-CL')}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Resumen Honorarios */}
                                        <div className="bg-white p-6 rounded-xl shadow-lg border-t-4 border-green-500">
                                            <h2 className="text-2xl font-semibold mb-4 text-gray-800 text-center flex items-center justify-center">
                                                <span className="text-green-500 mr-2">💼</span> Resumen Honorarios
                                            </h2>
                                            <div className="space-y-2">
                                                <div className="flex justify-between">
                                                    <span className="text-gray-600">Cantidad de Honorarios:</span>
                                                    <span className="font-medium text-gray-800">{resumenHonorarios.cantidadHonorarios}</span>
                                                </div>
                                                <div className="flex justify-between">
                                                    <span className="text-gray-600">Total Monto Facturado:</span>
                                                    <span className="font-medium text-gray-800">
                                                        ${resumenHonorarios.totalMonto.toLocaleString('es-CL')}
                                                    </span>
                                                </div>
                                                <div className="flex justify-between">
                                                    <span className="text-gray-600">Total Abonado:</span>
                                                    <span className="font-medium text-gray-800">
                                                        ${resumenHonorarios.totalPagado.toLocaleString('es-CL')}
                                                    </span>
                                                </div>
                                                <div className="flex justify-between">
                                                    <span className="text-gray-600">Total Pendiente:</span>
                                                    <span className="font-medium text-gray-800">
                                                        ${resumenHonorarios.totalPendiente.toLocaleString('es-CL')}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Listado de Facturas */}
                                    <div className=" mb-8">
                                        <h2 className="text-2xl font-semibold mb-4 text-blue-600 text-center flex items-center justify-center">
                                            <span className="text-blue-500 mr-2">📄</span> Facturas
                                        </h2>
                                        <div className="overflow-x-auto mb-8">
                                            <table className="min-w-full bg-white">
                                                <thead>
                                                    <tr className="bg-blue-500 text-white uppercase text-sm leading-normal">
                                                        <th className="py-3 px-6 text-center">
                                                            <input
                                                                type="checkbox"
                                                                checked={isAllSelected}
                                                                onChange={handleSelectAll}
                                                                className="form-checkbox h-4 w-4 text-blue-600"
                                                            />
                                                        </th>
                                                        <th className="py-3 px-6 text-left">Número</th>
                                                        <th className="py-3 px-6 text-left">Fecha Emisión</th>
                                                        <th className="py-3 px-6 text-left">Fecha Pago</th>
                                                        <th className="py-3 px-6 text-left">Monto</th>
                                                        <th className="py-3 px-6 text-left">Total Abonado</th>
                                                        <th className="py-3 px-6 text-left">Total Adeudado</th>
                                                        <th className="py-3 px-6 text-left">Estado</th>
                                                        <th className="py-3 px-6 text-left">Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-gray-600 text-sm">
                                                    {facturas.length > 0 ? (
                                                        facturas.map((factura) => (
                                                            <tr key={factura._id} className="border-b hover:bg-gray-100">
                                                                {/* Checkbox de Selección Individual */}
                                                                <td className="py-3 px-6 text-center">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={selectedFacturas.includes(factura._id)}
                                                                        onChange={() => handleSelectFactura(factura._id)}
                                                                        className="form-checkbox h-4 w-4 text-blue-600"
                                                                    />
                                                                </td>
                                                                <td className="py-3 px-6 text-left">{factura.numero}</td>
                                                                <td className="py-3 px-6 text-left">
                                                                    {new Date(factura.fechaEmision).toLocaleDateString('es-CL')}
                                                                </td>
                                                                <td className="py-3 px-6 text-left">
                                                                    {new Date(factura.fechaPago).toLocaleDateString('es-CL')}
                                                                </td>
                                                                <td className="py-3 px-6 text-left">
                                                                    ${factura.monto.toLocaleString('es-CL')}
                                                                </td>
                                                                <td className="py-3 px-6 text-left">{factura.total_abonado.toLocaleString('es-CL')}</td> {/* Total Abonado */}
                                                                <td className="py-3 px-6 text-left">{(factura.monto - factura.total_abonado).toLocaleString('es-CL')}</td> {/* Total Adeudado */}
                                                                <td className="py-3 px-6 text-left ">
                                                                    <span className={`py-1 px-3 rounded-full text-xs ${factura.estado === 'pagada' ? 'bg-green-200 text-green-600' : factura.estado === 'abonada' ? 'bg-blue-200 text-blue-600' : 'bg-yellow-200 text-yellow-600'}`}>
                                                                        {factura.estado}
                                                                    </span>
                                                                </td>
                                                                <td className="py-3 px-6 text-left">
                                                                    <div className="flex space-x-2">
                                                                        <button
                                                                            onClick={() => handleEditFactura(factura)}
                                                                            aria-label="Editar factura" className="bg-blue-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-blue-600"
                                                                        >
                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                        </button>
                                                                        {/* <Link to={{
                                                                            pathname: `/facturas/editar/${factura._id}`,
                                                                            search: location.search, // Pasa los parámetros de búsqueda aquí
                                                                            state: { from: 'DetalleCliente' },
                                                                        }} aria-label="Editar factura" className="bg-blue-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-blue-600">
                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                        </Link> */}

                                                                        <button
                                                                            onClick={() => handleAbonarFactura(factura)} aria-label="Abonar factura"
                                                                            className={`bg-yellow-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-yellow-600 ${factura.estado === 'pagada' ? 'opacity-50 cursor-not-allowed' : ''
                                                                                }`}
                                                                        >
                                                                            <FontAwesomeIcon icon={faDollarSign} />
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="4" className="py-3 px-6 text-center">
                                                                No hay facturas disponibles.
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    {/* Listado de Honorarios */}
                                    <div className="mb-8">
                                        <h2 className="text-2xl font-semibold mb-4 text-green-600 text-center flex items-center justify-center">
                                            <span className="text-green-500 mr-2">💼</span> Honorarios
                                        </h2>
                                        <div className="overflow-x-auto">
                                            {/* Botones de Acción para Honorarios */}
                                            <div className="flex space-x-2 mb-4">
                                                <button
                                                    onClick={handlePagoMasivoHonorarios}
                                                    disabled={selectedHonorarios.length === 0 || loading}
                                                    className={`px-4 py-2 bg-green-500 text-white rounded ${selectedHonorarios.length === 0 || loading
                                                        ? 'opacity-50 cursor-not-allowed'
                                                        : 'hover:bg-green-600'
                                                        }`}
                                                >
                                                    Pagar Seleccionados
                                                </button>
                                                <button
                                                    onClick={handleEliminarHonorariosMasivo}
                                                    disabled={selectedHonorarios.length === 0 || loading}
                                                    className={`px-4 py-2 bg-red-500 text-white rounded ${selectedHonorarios.length === 0 || loading
                                                        ? 'opacity-50 cursor-not-allowed'
                                                        : 'hover:bg-red-600'
                                                        }`}
                                                >
                                                    Eliminar Seleccionados
                                                </button>
                                            </div>
                                            {/* Botones de Acción para Honorarios */}
                                            <table className="min-w-full bg-white">
                                                <thead>
                                                    <tr className="bg-green-500 text-white uppercase text-sm leading-normal">
                                                        <th className="py-2 px-4 border">
                                                            <input
                                                                type="checkbox"
                                                                checked={isAllHonorariosSelected}
                                                                onChange={handleSelectAllHonorarios}
                                                            />
                                                        </th>
                                                        <th className="py-3 px-6 text-left">Fecha Emisión</th>
                                                        <th className="py-3 px-6 text-left">Fecha Pago</th>
                                                        <th className="py-3 px-6 text-left">Monto</th>
                                                        <th className="py-3 px-6 text-left">Estado</th>
                                                        <th className="py-3 px-6 text-left">Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-gray-600 text-sm">
                                                    {honorarios.length > 0 ? (
                                                        honorarios.map((honorario) => (
                                                            <tr key={honorario._id} className="border-b even:bg-gray-50 hover:bg-gray-100">
                                                                <td className="py-2 px-4 border">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={selectedHonorarios.includes(honorario._id)}
                                                                        onChange={() => handleSelectHonorario(honorario._id)}
                                                                    />
                                                                </td>
                                                                <td className="py-3 px-6 text-left">
                                                                    {new Date(honorario.fechaEmision).toLocaleDateString('es-CL')}
                                                                </td>
                                                                <td className="py-3 px-6 text-left">
                                                                    {new Date(honorario.fechaPago).toLocaleDateString('es-CL')}
                                                                </td>
                                                                <td className="py-3 px-6 text-left">
                                                                    ${honorario.monto.toLocaleString('es-CL')}
                                                                </td>
                                                                <td className="py-3 px-6 text-left">
                                                                    <span className={`py-1 px-3 rounded-full text-xs ${honorario.estado === 'pagado' ? 'bg-green-200 text-green-600' : honorario.estado === 'abonado' ? 'bg-blue-200 text-blue-600' : 'bg-yellow-200 text-yellow-600'}`}>
                                                                        {honorario.estado}
                                                                    </span>
                                                                </td>
                                                                <td className="py-3 px-6 text-left">
                                                                    <div className="flex space-x-2">
                                                                        <Link to={`/honorarios/editar/${honorario._id}${location.search}`} aria-label="Editar honorario" className="bg-blue-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-blue-600">
                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                        </Link>
                                                                        <button
                                                                            onClick={() => navigate(`/abonosHonorarios/${honorario._id}${location.search}`, { state: { tipo: 'honorario' } })}
                                                                            className={`bg-yellow-500 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-yellow-600 ${honorario.estado === 'pagada' ? 'opacity-50 cursor-not-allowed' : ''}`}

                                                                        >
                                                                            <FontAwesomeIcon icon={faDollarSign} />
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="3" className="py-3 px-6 text-center">
                                                                No hay honorarios disponibles.
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
}
export default DetalleCliente;