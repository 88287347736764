// src/components/EditarFactura.jsx

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import api from '../api';
import FacturaForm from './FacturaForm';
import Swal from 'sweetalert2';

const EditarFactura = () => {
  const { id } = useParams();
  const [factura, setFactura] = useState(null);
  const navigate = useNavigate();
  const location = useLocation()
  const rutCliente = location.state?.rutCliente;

  // Cargar la factura existente
  useEffect(() => {
    const fetchFactura = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await api.get(`/facturas/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setFactura(response.data);
      } catch (error) {
        console.error('Error al cargar la factura:', error);
      }
    };
    fetchFactura();
  }, [id]);

  const handleSubmit = async (formData) => {
    const token = localStorage.getItem('token');
    try {
      await api.put(
        `/facturas/${id}`,
        {
          numero: formData.numero,
          clienteRut: formData.clienteSeleccionado.value,
          fechaEmision: formData.fechaEmision,
          fechaPago: formData.fechaPago || null,
          estado: formData.estado,
          monto: formData.monto,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      Swal.fire('Éxito', 'Factura actualizada correctamente', 'success');
      navigate(`/facturas${location.search}`);
    } catch (error) {
      console.error('Error al actualizar la factura:', error);
      Swal.fire('Error', 'No se pudo actualizar la factura', 'error');
    }
  };


  // useEffect(() => {
  //   console.log('location.state:', location.state);
  //   console.log('Location search en EditarFactura:', location.search);
  // }, []);

  const handleVolver = () => {
    const from = location.state?.from;
    const search = location.search || '';

    if (from === 'DetalleCliente') {
      navigate(`/clientes/detalle/${rutCliente}`);
    
    } else {
      // Valor por defecto si no se conoce de dónde vino
      navigate(`/facturas${search}`);
    }
  };

  return (
    <div>
      {factura ? (
        <FacturaForm
          initialData={{
            numero: factura.numero,
            clienteSeleccionado: {
              value: factura.clienteRut,
              label: `${factura.clienteNombre} - ${factura.clienteRut}`,
            },
            fechaEmision: factura.fechaEmision ? factura.fechaEmision.substring(0, 10) : '',
            fechaPago: factura.fechaPago ? factura.fechaPago.substring(0, 10) : '',
            estado: factura.estado,
            monto: factura.monto,
          }}
          onSubmit={handleSubmit}
          onCancel={handleVolver}
        />
      ) : (
        <div className="flex justify-center items-center h-screen">
          <p className="text-gray-700">Cargando...</p>
        </div>
      )}
    </div>
  );
};

export default EditarFactura;